export enum COLLECTIONS {
    MARKETPLACE = 'marketplace',
    MOTO_MINTED_METAS = 'motoMintedMetas',
    mintAllocations = 'mintAllocations',


    users = 'users',
    inventory = 'inventory',

    newslettersignups = 'newslettersignups',

    statsUser = 'statsUser',
    activities = 'activities',
}
