import React from 'react'
import { UPGRADE_EMPTY, UPGRADE_HALF, UPGRADE_FULL } from '../../assets/svgs';
import { CLASS_MAX_UPGRADE, DEFAULT_MAX_UPGRADE } from '../../const/bikes';

type LineState = 'empty' | 'half' | 'full' | 'disabled';

const IMG_MAPPING: Record<LineState, JSX.Element> = {
    empty: UPGRADE_EMPTY,
    half: UPGRADE_HALF,
    full: UPGRADE_FULL,
    disabled: UPGRADE_EMPTY,
}

interface Props {
    value: number
    bikeClass: string
    upgradeType: string,
    className?: string
}

export default function BikeUpgradeCounter(props: Props) {
    const lines: LineState[] = new Array(6).fill('empty');
    const { value, bikeClass } = props


    const maxUpgrade = (CLASS_MAX_UPGRADE[bikeClass] && CLASS_MAX_UPGRADE[bikeClass][props.upgradeType]) || DEFAULT_MAX_UPGRADE;

    for (let lineIx = 0; lineIx < lines.length; lineIx++) {
        const fullValueOfLine = (lineIx + 1) * 2;
        if (value >= fullValueOfLine) {
            lines[lineIx] = 'full';
        } else if (value > fullValueOfLine - 2) {
            lines[lineIx] = 'half';
        } else if (maxUpgrade < fullValueOfLine) {
            lines[lineIx] = 'disabled';
        }
    }

    return (
        <div className={`d-flex upgrade-lines param_${props.upgradeType}_color ${props.className}`}>
            {lines.map((line, i) => {
                return <span key={i} className={line === 'disabled' ? 'disabled' : ''}>
                    {IMG_MAPPING[line]}
                </span>;
            })}
        </div>
    )
}
