import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

type AdressDisplayProps = {
    account?: string;
    className?: string;
    link?: string;
};

export default function AdressDisplay({ account, className, link }: AdressDisplayProps) {
    const copyAddress = () => {
        if (account) {
            navigator.clipboard.writeText(account);
        }
    }

    let shortenedAccount = account;
    if (account) {
        shortenedAccount = account.slice(0, 6) + '...' + account.slice(-4);
    }

    return (
        <div className={`d-flex align-items-center ${className || ''}`}>
            {link ? <Link to={link}>{shortenedAccount}</Link> : shortenedAccount}
            <button className='btn btn-inline mx-2' onClick={copyAddress}>
                <FontAwesomeIcon icon={faCopy} className='color-grey' />
            </button>
            <a href={`https://snowtrace.io/address/${account}`} target='_blank' rel="noreferrer">

                <button className='btn btn-inline ' onClick={copyAddress}>
                    <FontAwesomeIcon icon={faExternalLinkAlt} className='color-grey' />
                </button>
            </a>
        </div>
    )

}
