import React, { useContext, useMemo } from "react"
import { useUserContext } from "./UserContext";

const LEVEL_BOUNDARIES: Record<number, number> = {
    1: 720,
    2: 8730,
    3: 37581,
    4: 105864,
    5: 236387,
    6: 455697,
    7: 793753,
    8: 1283679,
    9: 1961577,
    10: 2866371,
    11: 4039672,
    12: 5525670,
    13: 7371036,
    14: 9624837,
    15: 12338462,
    16: 15565548,
    17: 19361928,
    18: 23785567,
    19: 28896514,
}



const DEFAULT_VALUE = { exp: 0, nextLevelBreak: 0, prevLevelBreak: 0, level: 0 };

interface MExpContext {
    exp: number,
    level: number,
    nextLevelBreak: number,
    prevLevelBreak: number,
}

const mExpContext = React.createContext<MExpContext>(DEFAULT_VALUE);

export function useMExp() {
    return useContext(mExpContext)
}


export default function MExpContextProvider({ children }: any) {
    const { user } = useUserContext();

    const value = useMemo(() => {
        const exp = user.exp || 0;
        let level = 1;
        let prevLevelBreak = 0;
        let nextLevelBreak = LEVEL_BOUNDARIES[1];
        while (LEVEL_BOUNDARIES[level] && exp > LEVEL_BOUNDARIES[level]) {
            level++;
            prevLevelBreak = nextLevelBreak;
            nextLevelBreak = LEVEL_BOUNDARIES[level];
        }

        return {
            exp,
            level,
            prevLevelBreak,
            nextLevelBreak,
        }
    }, [user.exp])

    return (
        <mExpContext.Provider value={value} >
            {children}
        </mExpContext.Provider>
    )
}