import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import './index.css';
import * as Sentry from "@sentry/react";
import App from './App';
import reportWebVitals from './reportWebVitals';
import PiwikPro from '@piwikpro/react-piwik-pro';
import 'bootstrap-icons/font/bootstrap-icons.css';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: "https://5f00b4dc04fb495f896fb70b1f49edb1@o342988.ingest.sentry.io/6221745",
    integrations: [new Sentry.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1,
    release: 'test1'
  });


  PiwikPro.initialize('0487bc8c-a1e8-4d3b-b7ff-c814ad5f3d70', 'https://beaver1.containers.piwik.pro');
}

const container = document.getElementById('root');

const root = ReactDOMClient.createRoot(container!);
root.render(
  <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
