const useQueryParam = () => {
    const search = window.location.search;
    const searchParams = new URLSearchParams(search);
    const preselect = searchParams.get('preselect');
    const debug = searchParams.get('debug');
    const page = searchParams.get('page');
    return {
        preselect,
        debug,
        page,
        subscriptionId: searchParams.get('subscriptionId'),
    }
}

export default useQueryParam;