import React from "react";
import useQueryParam from "../hooks/useQueryParams";
import { useFunctions } from "../hooks/useFunctions";

const UnsubscribePage = () => {
  const { subscriptionId } = useQueryParam();
  const [processing, setProcessing] = React.useState(false);
  const [unsubscribeError, setUnsubscribeError] = React.useState('');
  const [unsubscribeSuccess, setUnsubscribeSuccess] = React.useState(false);
  const { unsubscribe } = useFunctions();

  const unsubscribeHandler = async () => {
    setProcessing(true);
    try {
      await unsubscribe(subscriptionId + '');
      setUnsubscribeSuccess(true);
    } catch (error) {
      setUnsubscribeError((error as any).message);
    }
    setProcessing(false);
  };

  if (unsubscribeSuccess) {
    return (
      <div className="center flex-column flex-1 container">
        <h1>Unsubscribed</h1>
        <p className="text-center">
          You have been successfully unsubscribed from Motoverse.
        </p>
      </div>
    );
  }

  return (
    <div className="center flex-column flex-1 container">
      <h1>Considering Taking a Break?</h1>
      <p className="text-center">
        Before you hit the unsubscribe button, consider the unique experience
        Motoverse offers.
      </p>
      <p className="text-center">
        Motoverse blends motorbike racing, DeFi strategy, and play-to-earn
        mechanics in a multiplatform game.
      </p>
      <p>Stay with us and ride the crypto future!</p>

      {unsubscribeError && <div className="alert alert-danger">{unsubscribeError}</div>}

      <button onClick={unsubscribeHandler} className="btn btn-primary" disabled={processing}>
        Unsubscribe {processing && <span className="spinner-border spinner-border-sm" />}
      </button>
    </div>
  );
};

export default UnsubscribePage;
