import { MetaMaskError } from "../const/errors";

export const swallowMetaMaskExceptionOrRethrow = (e: any) => {
    const message = e?.message || '';
    if (message.includes(MetaMaskError.denied)
        || message.includes(MetaMaskError.reverted)
        || message.includes(MetaMaskError.rejected)) {
        console.log('Supressed', e);
        return;
    }

    throw e;
}
