import React, { useContext, useEffect, useState } from "react"
import Web3 from 'web3';
import Loading from "../components/Loading";
import { useConfig } from "./ConfigContext";

interface Web3Context {
    chainId: number
    isCorrectChain: boolean,
    web3: Web3,
    account: string,
    accountOriginal: string,
    isAuthenticated: boolean,
    nativeBalance: string | undefined,
    connectAccounts: () => Promise<void>
    rebuild: () => void
}

const web3Context = React.createContext<Web3Context>({} as any);

export function useWeb3() {
    return useContext(web3Context)
}

const CORRECT_CHAIN_ID = {
    'staging': 43113,
    'local': 43113,
    'prod': 43114,
};

export default function Web3Provider({ children }: any) {
    const [web3, setWeb3] = useState<Web3 | null>(null);
    const [nativeBalance, setNativeBalance] = useState<string | undefined>();
    const [chainId, setChainId] = useState<number>(0);
    const [account, setAccount] = useState('');
    const config = useConfig();
    const environment = config.environment || 'staging';
    const isCorrectChain = chainId === CORRECT_CHAIN_ID[environment];
    const avaxRPC = Web3.givenProvider || config?.web3?.node || '';

    const rebuild = () => {
        const newWeb3 = new Web3(avaxRPC);
        setWeb3(newWeb3);
    }

    const periodicCheck = async () => {
        if (web3) {
            const accounts = await web3.eth.getAccounts();

            if (accounts && accounts.length) {
                const newAccount = accounts[0];
                if (newAccount !== account) {
                    setAccount(newAccount);
                }
            }
        }
    }

    useEffect(() => {
        if (!web3 || !account) {
            return;
        }
        const fetchBalance = async () => {
            const balance = await web3.eth.getBalance(account);
            setNativeBalance(balance);

        }
        fetchBalance();
    }, [account, web3])

    useEffect(() => {
        if (avaxRPC) {
            rebuild();
            (window as any).ethereum?.on('chainChanged', rebuild);
        }
        // eslint-disable-next-line
    }, [avaxRPC]);

    useEffect(() => {
        const interval = setInterval(periodicCheck, 1000);

        return () => {
            clearInterval(interval);
        }
        // eslint-disable-next-line
    }, [web3]);

    useEffect(() => {
        if (web3) {
            web3.eth.getChainId().then(setChainId);
        }
    }, [web3])


    const connectAccounts = async () => {
        if (web3) {
            await web3.givenProvider.request({ method: 'eth_requestAccounts' });
        } else {
            console.error('Tried to login without web3');
        }
    }

    if (!web3 || !chainId) {
        return <Loading />
    }

    console.log('Web3 ready');
    return (
        <web3Context.Provider value={{
            chainId,
            isCorrectChain,
            web3,
            rebuild,
            connectAccounts,
            account: account.toLowerCase(),
            accountOriginal: account,
            isAuthenticated: !!account,
            nativeBalance,
        }} >
            {children}
        </web3Context.Provider>
    )
}