import { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import racePlaceholder from '../assets/race_placeholder_3.png'
import BikeCard from '../components/BikeCard';
import Countdown from '../components/Countdown';
import Loading from '../components/Loading';
import UserLevel from '../components/UserLevel';
import { AuthPages, AUTH_PATH } from '../const/navigation';
import { useMyBikes } from '../contexts/MyBikesContext';
import { useWeb3 } from '../contexts/Web3Context';
import AdressDisplay from '../components/AdressDisplay';
import Avatar from '../components/Avatar';
import { useFirestore } from 'reactfire';
import { doc, setDoc } from 'firebase/firestore';
import { COLLECTIONS } from '../const/collections';
import { useUserContext } from '../contexts/UserContext';
import TwitterShare from '../components/TwitterShare';
import UserBalances from '../components/UserBalances';
import MotoMetadata from '../models/MotoMetadata';
import { formatAmount } from '../utils/format';
import { useFirebaseQuery } from '../hooks/firebaseHooks';
import Activity from '../models/Activity';
import { useFunctions } from '../hooks/useFunctions';
import { toast } from 'react-toastify';

interface BikeStatus {
    notSignedUpBikes: MotoMetadata[],
    readyToClaimBikes: MotoMetadata[],
    racingBikes: MotoMetadata[],
}

export default function DashboardPage() {
    const { accountOriginal } = useWeb3();
    const { bikes } = useMyBikes();
    const [processing, setProcessing] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const { user, signupsPerBike } = useUserContext();
    const db = useFirestore();
    const [nickname, setNickname] = useState('');
    const [nicknameInitialized, setNicknameInitialized] = useState(false);
    const [activities] = useFirebaseQuery<Activity>(COLLECTIONS.activities);
    const { claimActivityRewards } = useFunctions();

    useEffect(() => {
        if (user && !nicknameInitialized) {
            setNickname(user.nickname || '');
            setNicknameInitialized(true);
        }
    }, [nicknameInitialized, user])


    const activitiesById = useMemo(() => {
        const result: Record<string, Activity> = {};
        for (const activity of activities) {
            result[activity.id] = activity;
        }
        return result;
    }, [activities]);


    const bikeStatus: BikeStatus = useMemo(() => {
        if (!bikes) {
            return { notSignedUpBikes: [], readyToClaimBikes: [], racingBikes: [] };
        }

        const result: BikeStatus = {
            notSignedUpBikes: [],
            readyToClaimBikes: [],
            racingBikes: [],
        };

        const today = new Date();
        for (const bike of bikes) {
            const signUpRecord = signupsPerBike[bike.tokenId];
            if (signUpRecord) {
                if (signUpRecord.claimAt < today) {
                    result.readyToClaimBikes.push(bike)
                } else {
                    result.racingBikes.push(bike);
                }
            } else {
                result.notSignedUpBikes.push(bike);
            }
        }

        return result;
    }, [bikes, signupsPerBike]);

    const updateNickname = async (newNickname: string) => {
        setNickname(newNickname);
        await setDoc(doc(db, COLLECTIONS.users, user.address), { nickname: newNickname }, { merge: true });
    };

    const handleClaimReward = async () => {
        setProcessing(true);
        setShowModal(true);

        try {
            await claimActivityRewards();
        } catch (e) {
            console.error(e)
            toast.error('Failed to claim reward');
        }

        setProcessing(false);
    }

    const handleCancelRace = async (tokenId: string) => {
        setProcessing(true);

        const newSignups = (user.signups || []).filter(signup => signup.bikeId !== tokenId);
        await setDoc(doc(db, COLLECTIONS.users, user.address), { signups: newSignups }, { merge: true });

        setProcessing(false);
    }


    const bikeCount = bikes?.length || 0;
    const racingBikeCount = bikeCount - bikeStatus.notSignedUpBikes.length || 0;

    const claimTitle = 'Claim';
    return (
        <div className="container">
            <div className="row">

                <div className='col-md-6'>

                    <div className='center'>
                        <Avatar />
                        <input
                            className='form-control inline w-unset ms-3'
                            placeholder='Rider Nickname'
                            value={nickname}
                            onChange={(e) => updateNickname(e.target.value)}

                        />
                    </div>
                    <UserLevel />
                </div>
                <div className="col-md-6 mt-5">
                    <div className="dashboard_section_header">Wallet</div>
                    <AdressDisplay account={accountOriginal} className='account_adress h2' />
                    <UserBalances bikeCount={bikeCount} racingBikeCount={racingBikeCount} />
                </div>
            </div>

            <hr className="dashboard-hr" />

            {!!bikeStatus.readyToClaimBikes.length && <>
                <div className='d-flex align-items-center mt-5 mb-3'>
                    <h2 className="m-0 me-2">Ready to claim your rewards!</h2>
                </div>

                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
                    {bikeStatus.readyToClaimBikes.map(bike => {
                        const activityId = signupsPerBike[bike.tokenId]?.activityId || '';
                        const activityImage = activitiesById[activityId]?.image || racePlaceholder;
                        return <div className="col" key={bike.tokenId}>
                            <div className={`card`}>
                                <img src={activityImage} alt="race" className="card-img" />
                                {bike.image && <img src={bike.image} alt='bike' className="card_bike_mini" />}

                                <button className="btn btn-primary center_button"
                                    disabled={processing}
                                    onClick={() => handleClaimReward()}>{claimTitle} Rewards
                                </button>
                            </div>
                        </div>
                    }
                    )}
                </div>
            </>
            }

            {!!bikeStatus.notSignedUpBikes.length && <>
                <h2 className="mt-5">Motorbikes, ready for sign up!</h2>

                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
                    {bikeStatus.notSignedUpBikes.map(bike => <BikeCard key={bike.tokenId} bike={bike}
                        link={`/${AUTH_PATH}/${AuthPages.activities}?preselect=${bike.tokenId}`} />)}
                </div>
            </>

            }

            {!!bikeStatus.racingBikes.length && <>
                <h2 className="mt-5">Active Bikes</h2>

                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
                    {bikeStatus.racingBikes.map(bike => {
                        const signUpRecord = signupsPerBike[bike.tokenId];
                        const activityId = signUpRecord?.activityId || '';
                        const activityImage = activitiesById[activityId]?.image || racePlaceholder;

                        return <div className="col" key={bike.tokenId}>
                            <div className="card">
                                <img src={activityImage} alt="race" className="card-img" />
                                {bike.image && <img src={bike.image} alt='bike' className="card_bike_mini" />}

                                <button className="btn btn-secondary center_button"
                                    disabled={processing}
                                    onClick={() => handleCancelRace(bike.tokenId)}>Cancel
                                </button>

                                <Countdown from={signUpRecord?.claimAt || new Date()} className="card_countdown" />
                            </div>
                        </div>
                    })}
                </div>
            </>
            }

            <hr className="features-hr" />

            <Modal show={showModal} onHide={() => setShowModal(false)} className="modal fade" size='lg'>
                <div className="modal-content">
                    <div className='center flex-column pb-3'>
                        <div className='trophy center'
                            style={{ backgroundImage: 'url(/assets/Activity_Background_01.png)', }}>
                            {processing
                                ? <Loading />
                                : <img src='/assets/Reward_01.png' alt="trophy" className="p-1" />}
                        </div>
                        <h3 className="m-title mt-4">{processing ? 'Gathering results' : 'Finished!'}</h3>
                        <p className="m-text mb-5">{processing ? 'You will get a reward' : 'Your earned MParts and MExp!'}</p>
                        <div className="card h-100 dashboard_user_info ps-5 pe-5 m-2 center flex-column">
                            <div className="name">MParts</div>
                            <div className="value">{formatAmount(user.mParts + '')}</div>
                        </div>

                        <UserLevel className='w-100' />
                        <div className='center'>
                            <TwitterShare riderId={accountOriginal} text='I just finished riding my bikes and got some MExp and MParts. Come check out my collection!' />

                            <button type="button" className="btn btn-primary mx-2" onClick={() => setShowModal(false)}>Close</button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>


    )
}
