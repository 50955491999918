import { useState } from 'react'
import { useParams } from 'react-router-dom';
import Loading from '../components/Loading';
import { dateToSting, epochSecondsToDate } from '../utils/converters';
import BikeAnimation from '../components/BikeAnimation';
import { useWeb3 } from '../contexts/Web3Context';
import BikeUpgrades from '../components/bike/BikeUpgrades';
import TwitterShare from '../components/TwitterShare';
import AvatarView from '../components/AvatarView';
import { InfoIcon } from '../components/InfoIcon';
import { Link } from 'react-router-dom';
import { useFirebaseDoc } from '../hooks/firebaseHooks';
import MotoMetadata from '../models/MotoMetadata';
import { COLLECTIONS } from '../const/collections';
import { useIsOwner } from '../contexts/UserContext';


export default function BikePage() {
    const { bikeId } = useParams<'bikeId'>();
    const [bike] = useFirebaseDoc<MotoMetadata>(`${COLLECTIONS.MOTO_MINTED_METAS}/${bikeId}_latest`);
    const [processingUpgrade, setProcessingUpgrade] = useState(false);
    const { account } = useWeb3();
    const { isOwner, owner } = useIsOwner(bikeId || '', account);

    const bikeClass = bike?.attributeRecords.archetype || '';
    const showRunningAnimation = processingUpgrade || bike?.imageUpgradeNeeded;
    return (
        <div className='container'>

            {bike ?
                <div className="row mb-5">
                    <div className="col-xl-7">
                        <BikeAnimation bike={bike} animationType={showRunningAnimation ? 'upgrade' : 'running'} />
                        <div className='d-flex mb-4'>
                            {isOwner && <TwitterShare className='me-3' tokenId={bike.tokenId} />}
                            <Link to={`/history/${bikeId}`} className='btn btn-secondary'>View history</Link>
                        </div>
                    </div>
                    <div className="col-xl-5">

                        <div className="card p-4 bike-specs">

                            <div className="row">
                                <div className="col">
                                    <h4>{bike.name}</h4>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-3">
                                    <h3>Class</h3>
                                    <div><strong>{bikeClass.toString().toUpperCase()}</strong>
                                    </div>
                                </div>
                                {bike.manufactured &&
                                    <div className="col-3">
                                        <h3>Manufactured</h3>
                                        <div>{dateToSting(epochSecondsToDate(bike.manufactured))}</div>
                                    </div>
                                }
                                <div className="col-3">
                                    <h3>Rarity</h3>
                                    <div className={`${bike.attributeRecords?.rarity}-color bold`}>{bike.attributeRecords?.rarity}</div>
                                </div>
                                <div className="col-3">
                                    <div className='d-flex'>
                                        <div>
                                            <h3>Tier</h3>
                                            <div>{bike.attributeRecords.tier || 0}</div>
                                        </div>
                                        <InfoIcon className='ms-2' id='reenroll' infoContent={<div>
                                            <p>Tier is raised every 5 part upgrades on a bike and come with overall visual upgrade.</p>
                                        </div>} />
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <AvatarView address={owner + ''} />
                            <hr />
                            <BikeUpgrades bike={bike} processing={processingUpgrade} setProcessing={setProcessingUpgrade} />
                        </div>
                    </div>
                </div>


                : <div className="mx-auto text-center">
                    <Loading />
                    <h2>Your motorbike is on the way</h2>
                    <p>Please wait a moment</p>
                </div>}

        </div>
    )
}
