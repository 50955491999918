export enum Pages {
    home = "/",
    sign = 'sign',
    faq = 'faq',
    gallery = "gallery",
    garage = "garage",
    history = "history",
    rider = "rider",
    mint = "mint",
    whitepaper = "whitepaper",
}

export const AUTH_PATH = 'auth';

export enum AuthPages {
    garage = "garage",
    exchange = "exchange",
    gallery = "gallery",
    race = 'race',
    activities = 'activities',
    challenges = 'challenges',
    rankings = 'rankings',
    rider = "rider",
}

export const DISCORD_LINK = 'https://discord.gg/mRtPeRNKwn';
export const TWITTER_LINK = 'https://twitter.com/MotoverseGames';
export const FACEBOOK_LINK = 'https://www.facebook.com/MotoverseGames/';
export const INSTAGRAM_LINK = 'https://www.instagram.com/motoverse.games/';

export const PRERELEASE = 'prerelease';