import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { doc, setDoc } from 'firebase/firestore'
import React from 'react'
import { Modal } from 'react-bootstrap'
import { useFirestore } from 'reactfire'
import { COLLECTIONS } from '../const/collections'
import { useUserContext } from '../contexts/UserContext'
import { DEFAULT_HELMET } from '../const/bikes'

export default function Avatar() {
    const [showChangeAvatar, setShowChangeAvatar] = React.useState(false)
    const { user, inventory } = useUserContext();
    const db = useFirestore();

    const helmets = inventory.filter((item) => item.type === 'helmet');
    const canChangeAvatar = helmets.length > 0

    const setHelmet = async (image: string) => {
        user.avatar = image;
        await setDoc(doc(db, COLLECTIONS.users, user.address), { avatar: image }, { merge: true });
        setShowChangeAvatar(false);
    }

    const imgSrc = user.avatar || DEFAULT_HELMET;

    return (<>
        {canChangeAvatar

            ? <button
                className='btn btn-inline p-0 m-0 position-relative'
                onClick={() => setShowChangeAvatar(!showChangeAvatar)}
            >

                <img className="helmet" src={imgSrc} alt='helmet' />
                <FontAwesomeIcon
                    style={{ position: 'absolute', bottom: '0', right: '0' }}
                    className='color-grey' icon={faEdit} />
            </button>
            : <img className="helmet" src={imgSrc} alt='helmet' />
        }

        <Modal show={showChangeAvatar} onHide={() => setShowChangeAvatar(false)} className="modal fade">
            <Modal.Header closeButton>
                <Modal.Title>Change Helmet</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    {helmets.map((helmet) => {
                        return <div className='col-4' key={helmet.id}>
                            <button className='btn btn-inline' onClick={() => setHelmet(helmet.image)}>
                                <img src={helmet.image} alt={helmet.tokenId} className='w-100' />
                            </button>
                        </div>
                    })
                    }

                </div>
            </Modal.Body>
        </Modal>
    </>
    )
}
