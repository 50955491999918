import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MultiContentNode } from "../../models/MultiContentNode";

interface WhitepaperPageNavigationButtonProps {
    direction: 'left' | 'right',
    node: MultiContentNode,
    onClick: (id: number) => void
}
function WhitepaperPageNavigationButton({ direction, node, onClick }: WhitepaperPageNavigationButtonProps) {
    return (<div className='col'>
        <button className='btn w-100 center border' onClick={() => onClick(node.id)}>
            {direction === 'left' && <FontAwesomeIcon className='m-2' icon={faChevronLeft} />}
            {node.title}
            {direction === 'right' && <FontAwesomeIcon className='m-2' icon={faChevronRight} />}
        </button>
    </div>);
}

export default WhitepaperPageNavigationButton;