import { MultiContentNode } from "../models/MultiContentNode";

export const flatNode = (node: MultiContentNode): MultiContentNode[] => {
    if (!node) {
        return [];
    }
    const children = node.children;

    if (children.length === 0) {
        return [node];
    }

    return children.map(flatNode).reduce((acc, val) => acc.concat(val), []);
}