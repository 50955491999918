import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Loading from '../components/Loading';
import { COLLECTIONS } from '../const/collections';
import { useFirestore } from 'reactfire';
import { doc, getDoc } from 'firebase/firestore';
import { fromFirebaseDoc } from '../utils/firebase';
import { Button } from 'react-bootstrap';
import { useFirebaseDoc } from '../hooks/firebaseHooks';
import MotoMetadata from '../models/MotoMetadata';


export default function BikeHistoryPage() {
    const { bikeId } = useParams<'bikeId'>();
    const [bike] = useFirebaseDoc<MotoMetadata>(`${COLLECTIONS.MOTO_MINTED_METAS}/${bikeId}_latest`);

    const [bikes, setBikes] = useState<MotoMetadata[]>([]);
    const firestore = useFirestore();
    const [currentIx, setCurrentIx] = useState(0);

    useEffect(() => {
        if (!bike) {
            return;
        }

        const asyncEffect = async () => {

            const version = Number(bike.attributeRecords.version);
            const bikesPromises: Promise<MotoMetadata>[] = [];

            for (let i = 1; i <= version; i++) {
                const bikeRef = doc(firestore, `${COLLECTIONS.MOTO_MINTED_METAS}/${bikeId}_${i}`);
                bikesPromises.push(getDoc(bikeRef).then(snap => fromFirebaseDoc<MotoMetadata>(snap)));
            }
            const bikes = await Promise.all(bikesPromises);
            setBikes(bikes);
        }
        asyncEffect();

    }, [bike, bikeId, firestore])

    useEffect(() => {
        // listen on arrow keys
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'ArrowRight' && currentIx < bikes.length - 1) {
                setCurrentIx(currentIx + 1);
            }
            if (e.key === 'ArrowLeft' && currentIx > 0) {
                setCurrentIx(currentIx - 1);
            }
        }
        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);

    }, [currentIx, bikes])

    if (!bikes.length) {
        return <Loading />
    }
    const currentBike = bikes[currentIx];
    return (
        <div className='container'>
            <div className='center flex-column'>



                <img src={currentBike.image} alt={currentBike.tokenId} className="bike-img" />
                <div className='center'>
                    <Button onClick={() => setCurrentIx(currentIx - 1)} disabled={currentIx === 0}><i className='bi bi-chevron-double-left' /></Button>
                    <h3 className='my-0 mx-4'>Version {currentIx + 1}/{bikes.length}</h3>
                    <Button onClick={() => setCurrentIx(currentIx + 1)} disabled={currentIx === bikes.length - 1}><i className='bi bi-chevron-double-right' /></Button>
                </div>
            </div>
        </div>
    )
}
