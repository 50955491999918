let firebaseConfig = {
    apiKey: "AIzaSyBvK4LyIxGDL4E5YbssuA2CFZ4fbEly9G8",
    authDomain: "motoverse-ad355.firebaseapp.com",
    projectId: "motoverse-ad355",
    storageBucket: "motoverse-ad355.appspot.com",
    messagingSenderId: "733609456730",
    appId: "1:733609456730:web:7d92213ec53018f738d463"
};

if (process.env.REACT_APP_ENV === 'production') {
    firebaseConfig = {
        apiKey: "AIzaSyCZ3N7ujiArSIA--HaSCcOLJSKmDh0PBjE",
        authDomain: "motoverse-prod.firebaseapp.com",
        projectId: "motoverse-prod",
        storageBucket: "motoverse-prod.appspot.com",
        messagingSenderId: "205399239714",
        appId: "1:205399239714:web:cd4600b0a1e826c3dcae7b"
    };
}

export default firebaseConfig;