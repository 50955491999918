import React, { useEffect, useState } from 'react'
import { zeroPad } from '../utils/converters';

interface Props {
    from: Date
    className?: string
}

export default function Countdown(props: Props) {
    const [value, setValue] = useState('');

    useEffect(() => {
        const updateValue = () => {

            let secondsTotal = Math.round((props.from.getTime() - new Date().getTime()) / 1000);
            const negative = secondsTotal < 0;
            secondsTotal = Math.abs(secondsTotal);


            const minutesTotal = Math.floor(secondsTotal / 60)
            const seconds = secondsTotal % 60;

            const hoursTotal = Math.floor(minutesTotal / 60)
            const minutes = minutesTotal % 60;

            const days = Math.floor(hoursTotal / 24)
            const hours = hoursTotal % 24;

            if (negative) {
                setValue('Now!')
                return;
            }

            let result = '';
            if (days > 0) {
                result += `${days}d `;
            }
            result += `${hours}:${zeroPad(minutes)}:${zeroPad(seconds)}`;

            setValue(result)
        }

        const interval = setInterval(updateValue, 1000);
        return () => {
            clearInterval(interval)
        }
    }, [props.from])

    return (
        <div className={`${props.className || ''}`}>{value}</div>
    )
}
