import { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { CLASS_MAX_UPGRADE, DEFAULT_MAX_UPGRADE, UPGRADE_NAMES } from '../../const/bikes';
import { MOTO_UPGRADES } from '../../const/tokens';
import { useMExp } from '../../contexts/MExpContext';
import { useWeb3 } from '../../contexts/Web3Context';
import bikePlaceholderSrc from '../../assets/bike_placeholder.png';
import tuneUpArrowSrc from '../../assets/tune_up_arrow.png';
import BikeUpgradeCounter from './BikeUpgradeCounter';
import TwitterShare from '../TwitterShare';
import MotoMetadata from '../../models/MotoMetadata';
import { useIsOwner, useUserContext } from '../../contexts/UserContext';
import { calculateUpgradePrice } from '../../utils/bikes';
import { MPARTS_LOGO } from '../../assets/svgs';
import { formatAmount } from '../../utils/format';
import { useFunctions } from '../../hooks/useFunctions';
import { toast } from 'react-toastify';

interface Props {
    processing: boolean
    setProcessing: (processing: boolean) => void
    bike: MotoMetadata | null,
}

export default function BikeUpgrades(props: Props) {
    const { processing, setProcessing } = props;
    const { bike } = props;
    const { user } = useUserContext();

    const { account } = useWeb3();
    const { isOwner } = useIsOwner(bike?.tokenId || '', account);
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);
    const { level } = useMExp();
    const { upgradePart } = useFunctions();

    const bikeClass = bike?.attributeRecords.archetype || '';

    const handleUpgrade = async (upgradeName: UPGRADE_NAMES) => {
        if (bike) {
            setProcessing(true);

            try {
                await upgradePart(bike.tokenId, upgradeName);
                setShowUpgradeModal(true);
            } catch (error) {
                console.error(error);
                toast.error('Upgrade failed');
            }
            setProcessing(false);
        }
    }

    const renderUpgradeButton = (upgrade: number, maxUpgrade: number, upgradeName: UPGRADE_NAMES) => {
        if (!isOwner) {
            return <button className='btn btn-outline-secondary' disabled={true}>Only owner can upgrade the bike
            </button>
        }

        const maxTierReached = upgrade >= maxUpgrade;
        if (maxTierReached) {
            return <button className='btn btn-outline-secondary' disabled={true}>Max Tier Reached
            </button>
        }
        if (upgrade >= (level + 1)) {
            return <button className='btn btn-outline-secondary' disabled={true}>Level Up before upgrade
            </button>
        }

        const upgradePrice = calculateUpgradePrice(upgrade);

        const tooExpensive = upgradePrice > (user.mParts || 0);
        if (tooExpensive) {
            return <button className='btn btn-outline-secondary' disabled={true}>
                Not enough MParts. ({formatAmount(upgradePrice)} {MPARTS_LOGO} )
            </button>
        }

        return <button className='btn btn-outline-primary'
            disabled={processing || !isOwner}
            onClick={() => handleUpgrade(upgradeName)}
        >
            Upgrade
            ({formatAmount(upgradePrice)} {MPARTS_LOGO} )
        </button>
    }


    return (<>
        {MOTO_UPGRADES.map(upgradeItem => {
            const upgrade = Number(bike?.attributeRecords?.[upgradeItem.name]) || 0;
            const maxUpgrade = (CLASS_MAX_UPGRADE[bikeClass] && CLASS_MAX_UPGRADE[bikeClass][upgradeItem.name]) || DEFAULT_MAX_UPGRADE;

            return <div className='row mt-3' key={upgradeItem.name}>
                <div className="col-sm-6">
                    <h3>{upgradeItem.name}</h3>
                    <div className='d-flex align-items-center'>
                        <img src={upgradeItem.image} alt="acceleration" className="parameters me-2" />
                        <BikeUpgradeCounter className="detail" value={upgrade} upgradeType={upgradeItem.name} bikeClass={bikeClass as string} />
                    </div>
                </div>

                <div className="col-sm-6 d-grid my-1">
                    {renderUpgradeButton(upgrade, maxUpgrade, upgradeItem.name)}
                </div>

            </div>
        })}

        <Modal show={showUpgradeModal} onHide={() => setShowUpgradeModal(false)} className="modal fade" size='lg'>
            <div className="modal-content">
                <div className='position-relative'>
                    <img src={bikePlaceholderSrc} alt="bike" className='mw-100' />
                    <div className='center w-100 h-100 tune-up-container'>
                        <img src={tuneUpArrowSrc} alt="up" />
                    </div>
                </div>

                <div className="center flex-column">
                    <h3 className="m-title">Tuned Up!</h3>
                    <p className="m-text mb-5">Your motorbike has been upgraded!</p>
                    <div className='mb-5 center'>
                        <TwitterShare tokenId={bike?.tokenId || ''} text='I just upgraded my bike. Check out my pimped ride!' />
                        <button type="button" className="btn btn-primary mx-2" onClick={() => setShowUpgradeModal(false)}>Continue</button>
                    </div>
                </div>

            </div>
        </Modal>
    </>
    )
}
