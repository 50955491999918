import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { useUser } from 'reactfire';
import { useWeb3 } from '../contexts/Web3Context';
import SignPage from '../pages/SignPage';
import Loading from './Loading';

type Props = {
    children: any
}

export default function RequireAuth(props: Props) {
    const { account, isCorrectChain } = useWeb3();
    const [loading, setLoading] = useState(true);
    const { pathname } = useLocation();
    const { data: user } = useUser();

    useEffect(() => {
        const timeout = setTimeout(() => setLoading(false), 2000);
        return () => {
            clearTimeout(timeout)
        }
    }, [])

    if (user && account && user.uid === account && isCorrectChain) {
        return props.children;
    }

    if (loading) {
        return <Loading />
    }

    return <SignPage pathname={pathname} />

}
