import { collection, doc, onSnapshot, query, where } from "firebase/firestore";
import React, { useContext, useState, useEffect } from "react"
import { useFirestore } from "reactfire";
import { COLLECTIONS } from "../const/collections";
import InventoryItem from "../models/InventoryItem";
import User from "../models/User";
import { fromFirebaseDoc, fromFirebaseDocs } from "../utils/firebase";
import { useWeb3 } from "./Web3Context";
import { useFirebaseQuery } from "../hooks/firebaseHooks";
import SignUpRecord from "../models/SignupRecord";


interface UserContext {
    user: User,
    signupsPerBike: Record<string, SignUpRecord>,
    inventory: InventoryItem[],
}

const EMPTY_CONTEXT: UserContext = {
    user: {
        address: '',
    },
    inventory: [],
    signupsPerBike: {},
}

const userContext = React.createContext<UserContext>(EMPTY_CONTEXT);

export const useIsOwner = (tokenId: string, account: string) => {
    const [inventoryItems] = useFirebaseQuery<InventoryItem>(COLLECTIONS.inventory,
        where('tokenId', '==', tokenId),
        where('type', '==', 'bike'));
    const owner = inventoryItems?.[0]?.ownerId;
    const lowercaseOwner = owner?.toLowerCase();
    const lowercaseCurrentAccount = account?.toLowerCase();
    const isOwner = lowercaseOwner === lowercaseCurrentAccount;

    return {
        isOwner,
        owner
    }
}

export function useUserContext() {
    return useContext(userContext)
}

export default function UserContextProvider({ children }: any) {
    const [result, setResult] = useState<UserContext>(EMPTY_CONTEXT);
    const { account } = useWeb3();
    const db = useFirestore();

    useEffect(() => {
        if (!account) {
            return;
        }

        const userRef = doc(db, COLLECTIONS.users, account);
        const unsubscribeUser = onSnapshot(userRef, (doc) => {
            const userData = fromFirebaseDoc<User>(doc) || {};
            userData.address = account;

            setResult((prev) => ({
                ...prev,
                signupsPerBike: constructSignUpRecordPerBike(userData),
                user: userData
            }))
        });

        const q = query(collection(db, COLLECTIONS.inventory), where('ownerId', '==', account));
        const unsubscribeInventory = onSnapshot(q, (snapshot) => {
            const inventory = fromFirebaseDocs<InventoryItem>(snapshot.docs);

            setResult((prev) => ({
                ...prev,
                inventory
            }))
        });
        return () => {
            unsubscribeUser();
            unsubscribeInventory();
        };
    }, [account, db])

    return (
        <userContext.Provider value={result} >
            {children}
        </userContext.Provider>
    )
}

const constructSignUpRecordPerBike = (user: User) => {
    if (!user.signups) {
        return {};
    }

    const result: Record<string, SignUpRecord> = {};
    for (const signUpRecord of user.signups) {
        result[signUpRecord.bikeId] = signUpRecord;
    }
    return result;
}
