import { InfoIcon } from './InfoIcon';
import { useMExp } from '../contexts/MExpContext';
import { useUserContext } from '../contexts/UserContext';
import { formatAmount } from '../utils/format';


interface Props {
    bikeCount: number
    racingBikeCount: number
}
export default function UserBalances(props: Props) {
    const { bikeCount, racingBikeCount } = props;
    const { user } = useUserContext();
    const { level } = useMExp();


    return (
        <div className="row">
            <div className="col">
                <div className="card h-100 dashboard_user_info"                >
                    <div className="name">MParts</div>
                    <div className="value center">
                        <img src="/assets/mparts.svg" alt="mparts" className='token-icon me-2' />
                        {formatAmount(user.mParts)}
                    </div>
                    <div className='center justify-content-end'>
                        <InfoIcon
                            className="me-1"
                            infoContent={<div>MParts are used to upgrade Motorbikes</div>}
                            id="mparts-info"
                        />
                    </div>
                </div>
            </div>
            <div className="col">
                <div className="card h-100 dashboard_user_info">
                    <div className="name">Motorbikes</div>
                    <div className="value">{bikeCount}</div>
                    <div className='center justify-content-end'>
                        <InfoIcon
                            className="me-1"
                            infoContent={<div>
                                Bike counts:
                                <ul>
                                    <li>Total: {bikeCount}</li>
                                    <li>Active: {racingBikeCount}</li>
                                    <li>Total active spots: {level}</li>
                                    <li className='bold'>Free active spots: {level - racingBikeCount}</li>
                                </ul>
                            </div>}
                            id="mparts-info"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
