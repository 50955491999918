import { useUser } from "reactfire";
import { Functions } from "../const/firebase";
import { useConfig } from "../contexts/ConfigContext"
import { UPGRADE_NAMES } from "../const/bikes";


export const useFunctions = () => {
    const { functionsUrl_DEPRECATEDD, functionEndpoints } = useConfig();
    const { data: currentUser } = useUser();

    return {
        signUpForActivity: async (activityId: string, bikeId: string): Promise<void> => {
            const token = await currentUser?.getIdToken();
            const result = await fetch(functionEndpoints.signUpForActivity, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    activityId,
                    bikeId
                })
            })

            if (result.status !== 200) {
                throw new Error('Failed to sign up for activity');
            }
        },
        claimActivityRewards: async (): Promise<void> => {
            const token = await currentUser?.getIdToken();
            const result = await fetch(functionEndpoints.claimActivityRewards, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })

            if (result.status !== 200) {
                throw new Error('Failed to claim activity rewards');
            }
        },
        evaluateExpo: async (tokenId: string): Promise<RarityUpgradeResult> => {
            const endpoint = `${functionsUrl_DEPRECATEDD}/${Functions.evaluateExpo}`
            const result = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    tokenId
                })
            })
            return result.json();
        },
        unsubscribe: async (subscriptionId: string): Promise<void> => {
            const endpoint = `${functionsUrl_DEPRECATEDD}/unsubscribe`
            try {
                const result = await fetch(endpoint, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        subscriptionId
                    })
                });
                if (result.status !== 200) {
                    const body = await result.text();
                    throw new Error(body);
                }
            } catch (error) {
                throw new Error(`Failed to unsubscribe: ${error}`);
            }
        },
        upgradePart: async (tokenId: string, upgradeName: UPGRADE_NAMES): Promise<void> => {
            const token = await currentUser?.getIdToken();
            console.log('Upgrading part', tokenId, upgradeName,)
            const result = await fetch(functionEndpoints.upgradePart, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    tokenId,
                    upgradeName
                })
            })

            if (result.status !== 200) {
                throw new Error('Failed to upgrade part');
            }
        },
    }
}

export interface RarityUpgradeResult {
    upgraded: boolean,
    newRarity: string,
    conditions: {
        mint: boolean,
        tier: boolean,
        claims: boolean,
    }
}