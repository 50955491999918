import React from 'react'
import Activity from '../../models/Activity';
import checkMark from '../../assets/check_mark.svg';
import useQueryParam from '../../hooks/useQueryParams';
import { formatAmount } from '../../utils/format';

interface Props {
    activity: Activity
    selected: boolean
    onClick: (activity: Activity) => void,
}

function ActivityBlock(props: Props) {
    const { activity, selected, } = props
    const { debug } = useQueryParam()

    const alreadyInactive = activity.activeUntil && new Date(activity.activeUntil) < new Date();
    const upcoming = activity.activeFrom && new Date(activity.activeFrom) > new Date();
    const disabled = (activity.disabled || alreadyInactive || upcoming) && !debug;
    const limited = activity.activeFrom || activity.activeUntil;
    return <div className="col" key={activity.id}>
        <div className={`card bike-box h-100 pointer ${selected && 'select'} ${disabled && 'disabled'}`}
            onClick={disabled ? () => { } : () => props.onClick(activity)}>
            <img src={activity.image} alt='race' className={`card-img ${disabled ? 'faded' : ''}`} />
            <img src={checkMark} alt='check' className="check_mark" />
            {limited && <img
                className='limited-icon'
                src={'/assets/limited_offer_icon_01.svg'} alt="limited" />}

            {disabled
                ? <div className='info center flex-column '>
                    <h5 className='mt-4'>{activity.title}</h5>
                    {upcoming ? <p>{activity.length}</p> : <p>Not Available</p>}
                </div>
                : <div className='info flex-column align-items-center justify-content-between'>
                    <div className='center flex-column'>
                        <h5 className='mt-4'>{activity.title}</h5>
                        <p className='text-center'>{activity.description}</p>
                    </div>
                    <div>
                        <h5>Rewards</h5>
                        <p className='m-0'>{formatAmount(activity.rewardMParts)} MParts</p>
                        <p>{formatAmount(activity.rewardExp)} MExp</p>
                    </div>
                    <p>{activity.length}</p>
                </div>
            }
        </div>
    </div>
}

export default ActivityBlock
