import { useEffect, useState } from 'react'
import BikeCard from '../components/BikeCard';
import { AUTH_PATH, AuthPages } from '../const/navigation';
import MarketplaceBike from '../models/MarketplaceBike';
import { loadMarketplaceBikes } from '../utils/marketplace';
import VisibilitySensor from 'react-visibility-sensor';
import { useConfig } from '../contexts/ConfigContext';
import { useFirestore } from 'reactfire';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { CLASS, MotoRarityOrder } from '../const/bikes';

const LOAD_PAGE_SIZE = 10;

export default function GaleryPage() {
    const [classFilter, setClassFilter] = useState('');
    const [tierFilter, setTierFilter] = useState('');
    const [rarityFilter, setRarityFilter] = useState('');
    const [allMarketplaceBikes, setAllMarketplaceBikes] = useState<MarketplaceBike[]>([])
    const [loading, setLoading] = useState(false);
    const [isLoadingVisible, setLoadingVisible] = useState(false);
    const config = useConfig();
    const db = useFirestore();
    const isAuth = window.location.pathname.includes(AUTH_PATH);
    const totalSupply = config.bikeTotalCount;


    useEffect(() => {
        handleLoadMore();
        // eslint-disable-next-line
    }, [totalSupply])
    useEffect(() => {
        if (!loading && isLoadingVisible) {
            handleLoadMore();
        }
        // eslint-disable-next-line
    }, [loading, isLoadingVisible])

    const handleLoadMore = async () => {
        if (allMarketplaceBikes.length >= totalSupply) {
            // nothing more to load
            return
        }
        setLoading(true);
        const tokenIds = []
        for (let tokenAdjustment = 1; tokenAdjustment <= LOAD_PAGE_SIZE; tokenAdjustment++) {
            const tokenId = allMarketplaceBikes.length + tokenAdjustment;
            if (tokenId > totalSupply) {
                break;
            }
            tokenIds.push(tokenId + '');
        }

        const bikePage = await loadMarketplaceBikes(tokenIds, db);

        setAllMarketplaceBikes(allMarketplaceBikes.concat(bikePage));
        setLoading(false);
    }


    const marketplaceBikes = allMarketplaceBikes
        .filter(marketplaceBike => marketplaceBike.bike)
        .filter(marketplaceBike => {
            if (classFilter === '') {
                return true
            }

            return marketplaceBike.bike.attributeRecords.archetype === classFilter;
        })
        .filter(marketplaceBike => {
            if (tierFilter === '') {
                return true
            }

            return marketplaceBike.bike.attributeRecords.tier + '' === tierFilter;
        })
        .filter(marketplaceBike => {
            if (rarityFilter === '') {
                return true
            }
            return marketplaceBike.bike.attributeRecords.rarity === rarityFilter;
        });

    const resetFilters = () => {
        setClassFilter('')
        setTierFilter('')
        setRarityFilter('')
    }

    const hasFilter = classFilter !== '' || tierFilter !== '' || rarityFilter !== '';
    return (
        <div className="container mt-3">
            <div className='d-flex align-items-center justify-content-between mb-1'>
                <div className='d-flex align-items-center flex-wrap'>
                    <div className='d-flex align-items-center my-1 me-2'>
                        <label>Class:</label>
                        <select className='form-select w-unset mx-2' value={classFilter} onChange={event => setClassFilter(event.target.value)}>
                            <option value={''}>-- All --</option>
                            {[CLASS.classic, CLASS.cruiser, CLASS.offroad, CLASS.sport]
                                .map(classType => <option key={classType} value={classType}>{classType}</option>)}
                        </select>
                    </div>
                    <div className='d-flex align-items-center my-1 me-2'>
                        <label>Tier:</label>
                        <select className='form-select w-unset mx-2' value={tierFilter} onChange={event => setTierFilter(event.target.value)}>
                            <option value={''}>-- All --</option>
                            {['1', '2', '3', '4', '5', '6', '7']
                                .map(tier => <option key={tier} value={tier} >{tier}</option>)}
                        </select>
                    </div>

                    <div className='d-flex align-items-center my-1'>
                        <label>Rarity:</label>
                        <select className='form-select w-unset mx-2' value={rarityFilter} onChange={event => setRarityFilter(event.target.value)}>
                            <option value={''}>-- All --</option>
                            {MotoRarityOrder
                                .map(rarity => <option key={rarity} value={rarity} >{rarity}</option>)}
                        </select>
                    </div>
                </div>

                <button className={`btn ${hasFilter ? 'btn-primary' : 'btn-outline-primary'}`}
                    onClick={resetFilters}
                >
                    Reset Filters
                    <FontAwesomeIcon icon={faFilter} className='ms-2' />
                </button>
            </div>

            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 mb-5">
                {marketplaceBikes.map(({ bike, saleRecord }) =>
                    <BikeCard bike={bike}
                        link={`${isAuth ? '/' + AUTH_PATH : ''}/${AuthPages.garage}/${bike.tokenId}`}
                        key={bike.tokenId}
                        forSale={saleRecord.forSale}
                    />)}

            </div>
            {allMarketplaceBikes.length < totalSupply &&
                <VisibilitySensor onChange={setLoadingVisible}>
                    <div className='center m-3'>
                        <button className='btn btn-primary' onClick={handleLoadMore} disabled={loading}>{loading ? 'Loading ...' : 'Load More'}</button>
                    </div>
                </VisibilitySensor>
            }
        </div>
    )
}
