
import iconSpeed from '../assets/icon_speed.svg'
import iconAcceleration from '../assets/icon_acceleration.svg'
import iconHandling from '../assets/icon_handling.svg'
import iconGrip from '../assets/icon_grip.svg'
import iconDurability from '../assets/icon_durability.svg'
import { UPGRADE_NAMES } from './bikes'
interface UpgradeRecord {
    name: UPGRADE_NAMES,
    partName: string,
    image: string,
}

export const MOTO_UPGRADES: UpgradeRecord[] = [
    { name: UPGRADE_NAMES.speed, partName: 'engineTier', image: iconSpeed },
    { name: UPGRADE_NAMES.acceleration, partName: 'gearboxTier', image: iconAcceleration },
    { name: UPGRADE_NAMES.handling, partName: 'brakeTier', image: iconHandling },
    { name: UPGRADE_NAMES.grip, partName: 'wheelTier', image: iconGrip },
    { name: UPGRADE_NAMES.durability, partName: 'protectionTier', image: iconDurability },
];
