import { limit, orderBy } from 'firebase/firestore';
import { COLLECTIONS } from '../const/collections';
import AvatarView from '../components/AvatarView';
import { useFirebaseQuery } from '../hooks/firebaseHooks';

export default function RankingsPage() {
    const [topActiveDocs] = useFirebaseQuery<{ id: string }>(COLLECTIONS.statsUser, orderBy('claimCount', 'desc'), limit(5));
    const topActive = topActiveDocs.map(doc => doc.id);

    console.log(topActive);


    return <div className="container">
        <div className='row'>
            <div className='col-12 col-md-6 center'>
                <img src="/assets/rankings_icon_01.png" alt="rankings" className="img-fluid half-hero-image " />
            </div>
            <div className='col-12 col-md-6 center'>
                <div className='mb-2 mt-5'>
                    <h3 className="mb-4 center">Top 5 Most Active</h3>
                    {topActive.map((address, index) => <div key={address} className="row center">
                        <h3 className="col-1 m-0">{index + 1}</h3>
                        <div className="col-11"><AvatarView address={address} /></div>
                    </div>)}
                </div>
            </div>

        </div>
    </div>
}
