import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { DEFAULT_HELMET } from '../const/bikes';
import User from '../models/User';
import { useFirestore } from 'reactfire';
import { doc, getDoc } from 'firebase/firestore';
import { COLLECTIONS } from '../const/collections';
import AdressDisplay from '../components/AdressDisplay';
import { useUserBikes } from '../contexts/MyBikesContext';
import BikeCard from '../components/BikeCard';
import { AUTH_PATH, AuthPages } from '../const/navigation';

export default function RiderPage() {
    const address = (useParams<{ address: string }>().address + '').toLowerCase();
    const [user, setUser] = useState<User | null>(null);
    const db = useFirestore();
    const { bikes } = useUserBikes(address);
    const isAuth = window.location.pathname.includes(AUTH_PATH);


    useEffect(() => {
        const docRef = doc(db, COLLECTIONS.users, address);
        getDoc(docRef).then(userDoc => {
            if (userDoc.exists()) {
                setUser(userDoc.data() as User);
            }
        }).catch(error => console.error('Error fetching user Data:', error))
    }, [address, db]);

    console.log('bikes', bikes);
    return (
        <div className='container'>
            <div className='d-flex align-items-center mb-4'>
                <img src={user?.avatar || DEFAULT_HELMET} alt='avatar' className='helmet me-3' />
                <div>
                    {user?.nickname && <h2 className='m-0'>{user?.nickname}</h2>}
                    <AdressDisplay account={address} className='h2 m-0' />
                </div>
            </div>

            {!!bikes?.length && <h2 >Collection</h2>}
            <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-4 mb-4">
                {(bikes || []).map(bike => <BikeCard bike={bike}
                    key={bike.id}
                    link={`${isAuth ? '/' + AUTH_PATH : ''}/${AuthPages.garage}/${bike.tokenId}`} />
                )}
            </div>
        </div>
    )
}
