import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Popover } from 'react-bootstrap';


interface Props {
    infoContent: JSX.Element
    id: string
    className?: string
}

export function InfoIcon({
    infoContent,
    className,
    id
}: Props) {

    const overlay = <Popover id={id}>
        <Popover.Body>
            {infoContent}
        </Popover.Body>
    </Popover>

    return <OverlayTrigger overlay={overlay}>
        <button className={`btn btn-inline p-0 ${className}`} type="button">
            <FontAwesomeIcon className='color-grey' icon={faInfoCircle} />
        </button>
    </OverlayTrigger>
}
