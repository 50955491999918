import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Loading from '../components/Loading'
import PublicHeader from '../components/PublicHeader'
import { Pages } from '../const/navigation'
import { useMultiContent } from '../hooks/useContent'
import useQueryParam from '../hooks/useQueryParams'
import WhitepaperPageNavigation from '../components/public/WhitepaperPageNavigation'
import WhitepaperLink from '../components/public/WhitepaperLink'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { flatNode } from '../utils/content'


export default function WhitepaperPage() {
    const multiContent = useMultiContent('whitepaper');
    const rootNode = multiContent.nodes[multiContent.root];
    const { page } = useQueryParam();
    const [currentId, setCurrentId] = useState(Number(page) || multiContent.root);
    const currentNode = multiContent.nodes[currentId];
    const navigate = useNavigate();
    const [navCollapsed, setNavCollapsed] = useState(false);

    useEffect(() => {
        if (rootNode && !page) {
            const flatten = flatNode(rootNode)
            setCurrentId(flatten[0].id);
        }
    }, [rootNode, page])

    useEffect(() => {
        document.scrollingElement?.scrollTo(0, 0);
    }, [page])


    const handlePageChange = (id: number) => {
        setCurrentId(id);
        navigate(`/${Pages.whitepaper}?page=${id}`);
    }

    if (!currentNode) {
        return <>
            <PublicHeader />
            <div className='center m-auto'>
                <Loading />
            </div>
        </>
    }

    return (<>
        <PublicHeader />
        <div className='d-flex w-100'>
            <div className={`navbar-light d-flex flex-column whitepaper-nav ${navCollapsed ? 'is-collapsed' : ''}`}>
                <div className='navbar-nav'>

                    {rootNode && <WhitepaperLink key={rootNode.title} node={rootNode} onClick={handlePageChange} level={0} active={currentId} />}
                </div>
                <button className='btn rounded-circle border whitepaper-nav-switch' onClick={() => setNavCollapsed(!navCollapsed)}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                </button>
            </div>
            <div className='d-flex justify-content-center w-100 whitepaper-margin '>

                <div className='container '>

                    <h3 className='mb-4'>{currentNode.title}</h3>
                    {currentNode.content}
                    <WhitepaperPageNavigation rootNode={rootNode} currentId={currentId} onClick={handlePageChange} />

                </div>
            </div>
        </div>
    </>
    )
}
