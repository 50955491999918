export const todayAtTime = (time: string): Date => {
    const today = new Date();
    const [hours, minutes] = time.split(':');

    today.setHours(Number(hours));
    today.setMinutes(Number(minutes));
    return today;
};

export const dateToSting = (uncheckedDate: Date | undefined | null): string => {
    const date = uncheckedDate ? new Date(uncheckedDate) : new Date();
    return (
        zeroPad(date.getDate()) +
        '.' +
        zeroPad(date.getMonth() + 1) +
        '.' +
        date.getFullYear()
    );
};

export const dateToUnixSting = (uncheckedDate: Date): string => {
    const date = uncheckedDate ? new Date(uncheckedDate) : new Date();
    return (
        date.getFullYear() +
        '-' +
        zeroPad(date.getMonth() + 1) +
        '-' +
        zeroPad(date.getDate())
    );
};

const formatTime = (date: Date) => {
    return zeroPad(date.getHours()) + ':' + zeroPad(date.getMinutes());
};


export const dateTimeToSting = (uncheckedDate: Date | undefined): string => {
    const date = uncheckedDate ? new Date(uncheckedDate) : new Date();
    return dateToSting(uncheckedDate) + ` ${formatTime(date)}`;
};


export const dateTimeToUnixSting = (uncheckedDate: Date): string => {
    const date = uncheckedDate ? new Date(uncheckedDate) : new Date();
    return (
        dateToUnixSting(date) + `T` +
        formatTime(date) +
        ':00'
    );
};

export const minutesToHoursString = (minutesTotal: number): string => {
    const hours = Math.floor(minutesTotal / 60);
    const minutes = minutesTotal - (hours * 60);

    return `${hours}h ${zeroPad(minutes)}m`;
};

export const minutesToHours = (minutesTotal: number): number => {
    const hours = Math.floor(minutesTotal / 60);
    const minutes = minutesTotal - (hours * 60);
    const minutesPart = Math.round(minutes * 100 / 60) / 100;

    return hours + minutesPart;
};

interface PrintAmountOptions {
    onlyAmount?: boolean
}

export const printAmount = (serialized: number, options: PrintAmountOptions = {}): string => {
    const isNegative = serialized < 0;
    const fullPart = Math.floor(Math.abs(serialized) / 100);
    const partialPart = Math.abs(serialized) % 100;

    const amount = `${isNegative ? '-' : ''}${fullPart}.${zeroPad(partialPart)}`;

    if (options.onlyAmount) {
        return amount;
    }

    return `${amount},-`;
};

export const parseAmount = (inputAmount: string): number => {
    const parts = inputAmount.split('.');
    if (!Number(parts[0])) {
        return 0;
    }

    const amount = Number(parts[0]) * 100;
    if (!parts[1]) {
        return amount;
    }

    const part2 = Number(zeroPad(parts[1], { right: true }));
    return amount < 0 ? amount - part2 : amount + part2;
};

interface ZeroPadOptions {
    right?: boolean
}
export function zeroPad(num: number | string, options: ZeroPadOptions = {}): string {
    const numString = num + '';
    if (numString.length > 1) {
        return numString;
    }

    if (options.right) {
        return numString + '0';
    }
    return '0' + numString;
}

export const calculateVatPart = (wholeAmount: number, taxRate: number) => {
    return Math.round(wholeAmount - wholeAmount * (100 / (100 + taxRate)));
};

export const epochSecondsToDate = (input: Number | string | undefined | null): Date | null => {
    if (input) {
        return new Date(Number(input) * 1000)
    }
    return null
}