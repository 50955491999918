import React from "react";
import { MultiContentNode } from "../../models/MultiContentNode";


interface WhitepaperLinkProps {
    node: MultiContentNode,
    onClick: (id: number) => void
    level: number
    active: number
}
function WhitepaperLink({ node, onClick, level, active }: WhitepaperLinkProps) {
    const baseClasses = 'btn nav-link p-1';
    const childNodes = node.children.map(childNode =>
        <WhitepaperLink key={childNode.title}
            node={childNode} onClick={onClick}
            level={level + 1} active={active} />);

    if (level === 0) {
        return <React.Fragment key={node.title}>
            {childNodes}
        </React.Fragment>
    };

    if (level > 0 && node.children.length > 0) {
        return <React.Fragment key={node.title}>
            <button className={`${baseClasses} bold text-uppercase mt-4`} disabled={true}>{node.title}</button>
            {childNodes}
        </React.Fragment>
    };

    return <React.Fragment key={node.title}>
        <button className={`${baseClasses} ${active === node.id ? 'active' : ''} ${level === 1 ? 'mt-3' : ''}`}
            onClick={() => onClick(node.id)}
        >{node.title}</button>
        {childNodes}
    </React.Fragment>
}

export default WhitepaperLink;
