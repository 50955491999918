
export enum UPGRADE_NAMES {
    handling = 'handling',
    speed = 'speed',
    acceleration = 'acceleration',
    grip = 'grip',
    durability = 'durability',
}

export enum CLASS {
    classic = 'classic',
    cruiser = 'cruiser',
    offroad = 'offroad',
    sport = 'sport',
}

export enum MotoRarity {
    Common = 'Common',
    Uncommon = 'Uncommon',
    Rare = 'Rare',
    Epic = 'Epic',
    Legendary = 'Legendary',
    Heirloom = 'Heirloom',
}

export const MotoRarityOrder = [MotoRarity.Common, MotoRarity.Uncommon, MotoRarity.Rare, MotoRarity.Epic, MotoRarity.Legendary, MotoRarity.Heirloom];

export const DEFAULT_MAX_UPGRADE = 6;
export const CLASS_MAX_UPGRADE: Record<string, Record<string, number>> = {
    [CLASS.cruiser]: {
        handling: 4,
        speed: 6,
        acceleration: 10,
        grip: 4,
        durability: 6,
    },
    [CLASS.offroad]: {
        handling: 6,
        speed: 4,
        acceleration: 4,
        grip: 8,
        durability: 8,
    },
    [CLASS.sport]: {
        handling: 6,
        speed: 10,
        acceleration: 8,
        grip: 4,
        durability: 2,
    },
}

export const DEFAULT_HELMET = '/assets/default01.png'
