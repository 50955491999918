import { NavLink } from 'react-router-dom';
import { AUTH_PATH, DISCORD_LINK, Pages, TWITTER_LINK, FACEBOOK_LINK, INSTAGRAM_LINK } from '../const/navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord } from '@fortawesome/free-brands-svg-icons'
import { faXTwitter } from '@fortawesome/free-brands-svg-icons'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { Container, Navbar } from "react-bootstrap";
import { recordMintEvent } from '../utils/analytics';

export default function PublicHeader() {

    const recordMintClick = () => recordMintEvent('mint_navbar');
    return (
        <>
            <div className="headerBox my-3">
                <div className="container">
                    <Navbar bg="" expand="lg">
                        <Container>
                            <Navbar.Brand href="/">
                                <img src='/assets/logo_motoverse.svg' alt="Motoverse" className="d-inline-block align-text-top logo" />
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <ul className="navbar-nav mb-2 mb-lg-0 justify-content-end w-100">
                                    <li><NavLink to={'/'} end className="nav-link px-2">Home</NavLink></li>
                                    <li><NavLink to={`/${Pages.gallery}`} end className="nav-link px-2">Gallery</NavLink></li>
                                    <li><NavLink to={`/${Pages.whitepaper}`} className="nav-link px-2">Whitepaper</NavLink></li>
                                    <li><NavLink to={`/${Pages.faq}`} className="nav-link px-2">FAQ</NavLink></li>
                                    <li><NavLink to={`/${AUTH_PATH}/`} end className="nav-link px-2" onClick={recordMintClick}>Login</NavLink></li>
                                    <li><a href={DISCORD_LINK} target="_blank" rel="noreferrer" className="nav-link px-2"><FontAwesomeIcon size="lg" className="discordLogo" icon={faDiscord} /></a></li>
                                    <li><a href={TWITTER_LINK} target="_blank" rel="noreferrer" className="nav-link px-2"><FontAwesomeIcon size="lg" className="twitterLogo" icon={faXTwitter} /></a></li>
                                    <li><a href={INSTAGRAM_LINK} target="_blank" rel="noreferrer" className="nav-link px-2"><FontAwesomeIcon size="lg" className="instagramLogo" icon={faInstagram} /></a></li>
                                    <li><a href={FACEBOOK_LINK} target="_blank" rel="noreferrer" className="nav-link px-2"><FontAwesomeIcon size="lg" className="facebookLogo" icon={faFacebook} /></a></li>
                                </ul>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                </div>
            </div>
        </>
    )
}
