import React from 'react';
import challengesSrc from '../assets/challenges_01.svg';
import {DISCORD_LINK} from '../const/navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord } from '@fortawesome/free-brands-svg-icons'


export default function RankingsPage() {

    return <>
        <div className="coming-soon">
            <div className="container">
                <div className="col-xl-6 m-auto">
                    <img src={challengesSrc} alt="challenges" className="img-fluid" />
                    <h1 className="mb-5">Challenges coming soon</h1>
                    <p className="lead">Join us on Discord and you won't miss any news!</p>
                    <a href={DISCORD_LINK} target="_blank" rel="noreferrer">
                        <button type="button" className="btn btn-secondary btn-lg px-5 mt-5">
                            <FontAwesomeIcon size="lg" className="" icon={faDiscord} /> Discord
                        </button>
                    </a>
                </div>
            </div>
        </div>
    </>
}
