import { ethers } from 'ethers';
import { signInWithCustomToken } from 'firebase/auth';
import React, { useState } from 'react'
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth, useUser } from 'reactfire';
import { SiweMessage, generateNonce } from 'siwe';
import Web3 from 'web3';
import PublicHeader from '../components/PublicHeader'
import { AVALANCHE_MAINNET_PARAMS, AVALANCHE_TESTNET_PARAMS } from '../const/avalanche';
import { AuthPages, AUTH_PATH } from '../const/navigation';
import { useConfig } from '../contexts/ConfigContext';
import { useWeb3 } from '../contexts/Web3Context';

const WALLET_AUTH_VERIFY = 'walletAuthVerify';

interface Props {
    pathname?: string
}

const MetamaskImg = () => <img src="/assets//metamask.svg" alt="metamask" className="metamask-logo" />

export default function SignPage(props: Props) {
    const hasMetamask = !!Web3.givenProvider;
    const { connectAccounts, web3, account, accountOriginal, isCorrectChain, chainId } = useWeb3();
    const { environment, functionsUrl_DEPRECATEDD: functionsUrl } = useConfig();
    const { data: user } = useUser();
    const [processing, setProcessing] = useState(false);
    const [failedToAddNetwork, setFailedToAddNetwork] = useState(false);
    const auth = useAuth();
    const networkSwitchParams = environment === 'prod' ? AVALANCHE_MAINNET_PARAMS : AVALANCHE_TESTNET_PARAMS;

    const successRedirectLocation = props.pathname || `/${AUTH_PATH}/${AuthPages.garage}`;

    const handleNetworkSwitch = async () => {
        try {
            await Web3!.givenProvider.request({
                method: 'wallet_addEthereumChain',
                params: [networkSwitchParams]
            })
        } catch (e) {
            console.log("failed to add network", e);
            setFailedToAddNetwork(true)
        }
    }

    const validate = async () => {
        setProcessing(true);
        try {
            if (!web3.currentProvider) {
                throw new Error('No web3 provider');
            }

            const nonce = generateNonce()

            const message = new SiweMessage({
                domain: window.location.host,
                address: accountOriginal,
                statement: 'Sign in to Motoverse.',
                uri: window.location.origin,
                version: '1',
                chainId,
                nonce,
            });
            const preperaredMessage = message.prepareMessage();


            const provider = new ethers.providers.Web3Provider(web3.currentProvider as any);
            const signer = provider.getSigner();
            const signature = await signer.signMessage(preperaredMessage);

            const verifyResponse = await fetch(`${functionsUrl}/${WALLET_AUTH_VERIFY}`, {
                method: 'POST',
                body: JSON.stringify({
                    message,
                    signature,
                })
            });
            const verifyResponseJson = await verifyResponse.json();

            if (!verifyResponseJson.ok) {
                throw new Error('Failed to verify');
            }

            await signInWithCustomToken(auth, verifyResponseJson.token);
        } catch (e) {
            console.error('Failed to verify', e);
            toast.error('Account verification failed');
        }
        setProcessing(false);
    }

    if (hasMetamask && isCorrectChain && account && user && user.uid === account) {
        return <Navigate to={successRedirectLocation} />
    }


    if (!hasMetamask) {
        return (
            <>
                <PublicHeader />
                <div className="hero_box flex-fill">
                    <div className="container">

                        <div className="col-xl-6 m-auto login">
                            <h1 className="mb-5 mt-2">You need MetaMask wallet to play motoverse</h1>
                            <p className="lead">Go to the MetaMask app, Browser tab and enter motoverse.games. <br />Or download the MetaMask
                                plugin for browser.</p>
                            <p className="lead">If you do not have a MetaMask wallet, you can set it up for free</p>
                            <a href='https://metamask.io/'>
                                <button type="button" className="btn btn-outline-primary btn-lg px-5 mt-5">
                                    <MetamaskImg /> Create MetaMask wallet
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    if (!isCorrectChain) {
        return (
            <>
                <PublicHeader />
                <div className="hero_box flex-fill">
                    <div className="container">

                        <div key="network" className="col-xl-6 m-auto login">
                            <h1 className="mb-5 mt-2">Not supported network detected</h1>
                            {failedToAddNetwork
                                ? <p className='alert alert-warning'>
                                    Automatic switch failed. Please switch manually to <span className='bold'>
                                        {networkSwitchParams.chainName}
                                    </span>
                                </p>
                                : <button type="button" className="btn btn-outline-primary btn-lg px-5 mt-5" onClick={handleNetworkSwitch}>
                                    <MetamaskImg />Switch Network
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }

    if (!account) {
        return (
            <>
                <PublicHeader />
                <div className="hero_box flex-fill">
                    <div className="container">

                        <div key="login" className="col-xl-6 m-auto login">
                            <h1 className="mb-5 mt-2">Not logged in</h1>
                            <button type="button" className="btn btn-outline-primary btn-lg px-5 mt-5" onClick={connectAccounts}>
                                <MetamaskImg />Login
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    if (!user || user.uid !== account) {
        return (
            <>
                <PublicHeader />
                <div className="hero_box flex-fill">
                    <div className="container">

                        <div key="login" className="col-xl-6 m-auto login">
                            <h1 className="mb-5 mt-2">Not verified</h1>
                            <button type="button" className="btn btn-outline-primary btn-lg px-5 mt-5" onClick={validate} disabled={processing}>
                                <MetamaskImg />Verify
                                {processing && <span className="ms-2 spinner-border spinner-border-sm" />}
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return <Navigate to={successRedirectLocation} />
}
