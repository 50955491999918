import { useEffect, useState } from "react";
import Loading from "../components/Loading";
import { useConfig } from "../contexts/ConfigContext";
import { MultiContentNode } from "../models/MultiContentNode";

export type ContentType = 'faq' | 'whitepaper';

const GET_CONTENT_PATH = 'getContent'

const PAGES: Record<ContentType, number> = {
    faq: 12,
    whitepaper: 70,
}

export const useContent = (type: ContentType): JSX.Element => {
    const config = useConfig();
    const [content, setContent] = useState<JSX.Element>(<Loading />);

    useEffect(() => {
        if (!config.functionEndpoints?.getContent) {
            return;
        }

        const endpoint = `${config.functionEndpoints.getContent}?page=${PAGES[type]}`;
        fetch(endpoint).then(async res => {
            const response = await res.json();
            const content = response.content.rendered;
            setContent(<div className="wp-content" dangerouslySetInnerHTML={{ __html: content }} />);
        })
    }, [config.functionEndpoints?.getContent, type])

    return content;
}

interface MultiContent {
    nodes: Record<number, MultiContentNode>,
    root: number,
}
export const useMultiContent = (type: ContentType): MultiContent => {
    const config = useConfig();
    const [nodes, setNodes] = useState<Record<number, MultiContentNode>>({});

    useEffect(() => {
        const endpoint = `${config.functionsUrl_DEPRECATEDD}/${GET_CONTENT_PATH}`;


        fetch(endpoint).then(async res => {
            const response = await res.json();


            const newNodes: Record<number, MultiContentNode> = {};

            const ensureNode = (id: number) => {
                if (!newNodes[id]) {
                    newNodes[id] = {
                        content: <div />,
                        title: '',
                        children: [],
                        order: 0,
                        id,
                    }
                }
            }

            const allItems = response;
            allItems.sort((a: any, b: any) => Number(a.menu_order) - Number(b.menu_order));
            for (const item of allItems) {
                const id = Number(item.id);
                ensureNode(id);

                newNodes[id].title = item.title?.rendered;
                newNodes[id].content = <div className="wp-content" dangerouslySetInnerHTML={{ __html: item.content?.rendered }} />;
                newNodes[id].order = Number(item.menu_order) || 0;

                if (item.parent) {
                    const parentId = Number(item.parent);
                    ensureNode(parentId);
                    newNodes[parentId].children.push(newNodes[id]);
                }
            }

            setNodes(newNodes);
        });
    }, [config.functionsUrl_DEPRECATEDD, type])

    return {
        nodes: nodes,
        root: PAGES[type],
    };
}
