import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { getPopupSeen, setPopupSeen } from '../../utils/localStorage';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useFirestore } from 'reactfire';
import { addDoc, collection } from 'firebase/firestore';
import { COLLECTIONS } from '../../const/collections';
import { useConfig } from '../../contexts/ConfigContext';
import useQueryParam from '../../hooks/useQueryParams';

const GENERAL_EMAIL_SIGNUP_POPUP = 'general_email_signup_popup';
const POPUP_DELAY_MS = 12000;

type Inputs = {
    name: string,
    email: string,
};

const validateEmail = (email: string) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const result = re.test(String(email).toLowerCase());
    return result;
};

export default function EmailCollectingPopup() {
    const [showModal, setShowModal] = React.useState(false);
    const [submitted, setSubmitted] = React.useState(false);
    const config = useConfig();
    const db = useFirestore();
    const { register, handleSubmit, formState: { errors } } = useForm<Inputs>();
    const { debug } = useQueryParam();
    const debugSale = debug === 'sale';

    useEffect(() => {
        const alreadySeen = getPopupSeen(GENERAL_EMAIL_SIGNUP_POPUP);
        const forceStopShowing = true;
        const show = (!forceStopShowing && !alreadySeen) || debugSale;
        if (show) {
            const timeout = setTimeout(() => {
                setPopupSeen(GENERAL_EMAIL_SIGNUP_POPUP);
                setShowModal(true);
            }, debugSale ? 100 : POPUP_DELAY_MS);
            return () => clearTimeout(timeout);
        }
    }, [config.environment, debugSale])

    const onSubmit: SubmitHandler<Inputs> = data => {

        // add to firebase
        addDoc(collection(db, COLLECTIONS.newslettersignups), {
            ...data,
            createdAt: new Date(),
        })

        setSubmitted(true);
    }


    return (
        <Modal show={showModal} onHide={() => setShowModal(false)} className="modal fade" size='lg'>
            <div className="modal-content">
                {submitted
                    ? <>

                        <div className="modal-body py-5">
                            <p className='center'>🚀 Thanks for joining our action-packed newsletter – let's ride! 🏍️</p>
                            <div className='center'>

                                <button type="button" className="btn btn-primary" onClick={() => setShowModal(false)}>Close</button>
                            </div>
                        </div>
                    </>
                    : <>
                        <div className="modal-header">
                            <h5 className="modal-title flex-1 center">👀 Stay alert – skyrocket earnings with the trendiest motorcycles! 🌟</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowModal(false)}></button>
                        </div>
                        <div className='modal-body'>
                            <ul className='list-unstyled'>
                                <li className='my-3'>
                                    🔥 Stay informed on high-demand motorcycles and boost your earnings.
                                </li>
                                <li className='my-3'>
                                    🚀 Capitalize on selling your NFTs with the most viewed trending bikes.
                                </li>
                                <li className='my-3'>
                                    💰 Enhance profits with top-trending motorcycle alerts.
                                </li>
                                <li className='my-3'>
                                    🌟 Stay ahead of the competition with sought-after bike trend notifications.
                                </li>
                                <li className='my-3'>
                                    📈 Optimize your buying and selling strategies for the ultimate motorcycle opportunities.
                                </li>
                                <li className='my-3'>
                                    🔧 Get eye-catching notifications about improved features and updates!
                                </li>
                            </ul>
                            <p className='center mt-4'>Join the trend and accelerate your success today! 🏆</p>
                            <form className="modal-body" onSubmit={handleSubmit(onSubmit)}>
                                <input type="text" className={`form-control ${errors.name ? 'is-invalid' : ''} `}
                                    placeholder="Name"
                                    {...register("name", { required: true })} />
                                <input type="email" className={`form-control mt-2 ${errors.email ? 'is-invalid' : ''} `}
                                    placeholder="Email"
                                    {...register("email", { required: true, validate: validateEmail })} />
                                <div className='center mt-4'>
                                    <button type="submit" className="btn btn-primary">Accelerate Your Profits! 🏁</button>

                                </div>

                            </form>
                        </div>
                    </>
                }
            </div>
        </Modal>
    )
}
