import { InfoIcon } from './InfoIcon';
import { useEffect, useState } from 'react'
import { useMExp } from '../contexts/MExpContext';

interface Props {
    className?: string
}



export default function UserLevel(props: Props) {
    const { exp, level, nextLevelBreak, prevLevelBreak } = useMExp();
    const [percentage, setPercantage] = useState(0);

    useEffect(() => {
        const totalPoints = nextLevelBreak - prevLevelBreak;
        const onePecent = totalPoints / 100
        const xpInThisLevel = exp - prevLevelBreak;
        const xpPercentage = xpInThisLevel / onePecent;
        setPercantage(xpPercentage)
    }, [nextLevelBreak, prevLevelBreak, exp])

    return (
        <div className={`text-center ${props.className || ''}`}>
            <div className="col-6 m-auto mt-4">
                <div className="progress">
                    <div className="progress-bar bg-red" style={{ width: percentage + '%' }} role="progressbar"
                        aria-valuenow={exp}
                        aria-valuemin={prevLevelBreak} aria-valuemax={nextLevelBreak} />
                </div>
                <div className="row mt-3">
                    <div className="col-auto me-auto level_number">
                        <div className='center h-100'>
                            <div>
                                Level <span>{level}</span>
                                <InfoIcon
                                    className='ms-1'
                                    infoContent={<div>
                                        Level {level} unlocks:
                                        <ul>
                                            <li>Tier {level + 1} Upgrades</li>
                                            <li>{level} Motorbikes Simultaneous Activity / Race</li>
                                        </ul>
                                    </div>}
                                    id="user-level"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-auto">
                        <strong>{exp || 0}</strong> / {nextLevelBreak} <img src="/assets/mexp.svg" className='token-icon' alt="XP" />
                    </div>
                </div>
            </div>

        </div>
    )
}
