import AdressDisplay from './AdressDisplay';
import { AUTH_PATH, Pages } from '../const/navigation';
import { DEFAULT_HELMET } from '../const/bikes';
import { COLLECTIONS } from '../const/collections';
import User from '../models/User';
import { Link } from 'react-router-dom';
import { useFirebaseDoc } from '../hooks/firebaseHooks';


interface Props {
    address: string;
}

export default function AvatarView(props: Props) {
    const { address } = props;
    const isAuth = window.location.pathname.includes(AUTH_PATH);
    const [user] = useFirebaseDoc<User>(`${COLLECTIONS.users}/${address.toLowerCase()}`);
    const riderPageLink = `${isAuth ? '/' + AUTH_PATH : ''}/${Pages.rider}/${address}`;

    return (
        <div className="d-flex align-items-center">
            <img className="smallHelmet me-3" src={user?.avatar || DEFAULT_HELMET} alt="helmet" />
            <div>
                {user?.nickname && <Link to={riderPageLink}><h2 className='m-0'>{user?.nickname}</h2></Link>}
                <AdressDisplay link={riderPageLink} account={address} />
            </div>
        </div>
    )
}
