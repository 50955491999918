import MarketplaceBike from "../models/MarketplaceBike";
import { doc, Firestore, getDoc } from "firebase/firestore";
import SaleRecord from "../models/SaleRecord";
import { COLLECTIONS } from "../const/collections";
import MotoMetadata from "../models/MotoMetadata";
import { fromFirebaseDoc } from "./firebase";

export const loadMarketplaceBikes = async (tokenIds: string[], db: Firestore): Promise<MarketplaceBike[]> => {

    const mintedMetasKeys = tokenIds.map(tokenId => `${tokenId}_latest`);

    const bikeRefPromises = mintedMetasKeys.map(key => getDoc(doc(db, COLLECTIONS.MOTO_MINTED_METAS, key)));
    const saleRecordRefPromises = tokenIds.map(tokenId => getDoc(doc(db, COLLECTIONS.MARKETPLACE, tokenId)));
    const bikeRefs = await Promise.all(bikeRefPromises);
    const saleRecordRefs = await Promise.all(saleRecordRefPromises);

    const bikes: MarketplaceBike[] = [];
    for (let ix = 0; ix < tokenIds.length; ix++) {
        const metaRef = bikeRefs[ix];
        const saleRecord = (saleRecordRefs[ix].data() as SaleRecord) || { forSale: false };

        bikes.push({
            bike: fromFirebaseDoc<MotoMetadata>(metaRef),
            saleRecord
        })
    }

    return bikes;
}