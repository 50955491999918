import React, { useEffect, useRef, useState } from 'react'
import { Canvas, useFrame, useLoader } from '@react-three/fiber';
import * as THREE from 'three';
import { useConfig } from '../contexts/ConfigContext';
import MotoMetadata from '../models/MotoMetadata';

type AnimationType = 'running' | 'upgrade';

interface Props {
    bike: MotoMetadata
    animationType: AnimationType;
}

export default function BikeAnimation(props: Props) {
    const { bike } = props;
    const [atlasKey, setAtlasKey] = useState(0);
    const [atlasLoaded, setAtlasLoaded] = useState('');
    const plainRef = useRef<HTMLImageElement>(null);
    const config = useConfig();
    const storageProxy = config.environment === 'staging' ? 'staging.motoverse.games' : 'motoverse.games';

    const atlasSrc = bike?.image
        .replace('.png', '_atlas.png')
        .replace('storage.googleapis.com', storageProxy + '/storage');

    const refetchAtlas = () => {
        setAtlasLoaded('');
        setTimeout(() => {
            console.log('refetching atlas')
            setAtlasKey(old => old + 1);
        }, 2000)
    }
    useEffect(() => { }, [plainRef.current?.height])

    const showAnimation = atlasLoaded === atlasSrc && plainRef.current?.height;
    return (
        <div className='position-relative w-100' >
            <div className={`opacity-trigger ${showAnimation ? 'hide' : ''}`}>
                <img ref={plainRef} id={bike.tokenId + '_plain'} src={bike.image} alt={bike.tokenId} className={`img-fluid`} />
            </div>
            <img id={bike.tokenId + '_atlas'} key={atlasKey}
                src={atlasSrc} alt={bike.tokenId}
                onError={refetchAtlas} onLoad={() => setAtlasLoaded(atlasSrc)}
                className="d-none" />
            <div id={bike.tokenId + '_animation_wrapper'}
                className={`position-absolute  opacity-trigger ${showAnimation ? '' : 'hide'}`}
                style={{ top: 0, left: 0 }}
            >
                <Canvas flat orthographic={true}
                    id={bike.tokenId + '_animation_canvas'}
                    style={{ height: plainRef.current?.height, width: plainRef.current?.width }}>
                    {showAnimation && <Animation atlasSrc={atlasSrc} tokenId={bike.tokenId} animationType={props.animationType} />}
                </Canvas>
            </div>
            {props.animationType === 'upgrade' && <img className='position-absolute rotating-element'
                style={{ top: 0, left: 0, width: plainRef.current?.width ? plainRef.current.width / 4 : 'unset' }}
                src="/assets/wrench.svg"
                alt="upgrade"
            />}
        </div>
    )
}
interface AnimationProps {
    atlasSrc: string;
    tokenId: string;
    animationType: AnimationType;
}
const Animation = (props: AnimationProps) => {
    const loader = THREE.TextureLoader;
    const origTexture = useLoader(loader, props.atlasSrc);

    return <>
        <Layer tokenId={props.tokenId} origTexture={origTexture}
            layer={0} animationType={props.animationType}
        />
        <Layer tokenId={props.tokenId} origTexture={origTexture}
            layer={1} runningAnimationSpeed={30} animationType={props.animationType}
            upgradeAnimationHight={3}
        />
        <Layer tokenId={props.tokenId} origTexture={origTexture}
            layer={2} runningAnimationSpeed={10} animationType={props.animationType}
            upgradeAnimationHight={4}
        />
        <Layer tokenId={props.tokenId} origTexture={origTexture}
            layer={3} runningAnimationSpeed={20} animationType={props.animationType}
            upgradeAnimationHight={1}
        />
        <Layer tokenId={props.tokenId} origTexture={origTexture}
            layer={4} runningAnimationSpeed={10} animationType={props.animationType}
            upgradeAnimationHight={2}
        />

    </>
}

type AnimationPhase = 'running' | 'stoped' | 'upgrade_up' | 'upgrade_down';

const CorrecspondingAnimationType: Record<AnimationPhase, AnimationType | null> = {
    running: 'running',
    stoped: null,
    upgrade_up: 'upgrade',
    upgrade_down: 'upgrade',
}

interface LayerProps {
    origTexture: THREE.Texture;
    layer: number;
    upgradeAnimationHight?: number;
    runningAnimationSpeed?: number;
    tokenId: string;
    animationType: AnimationType;
}
const MOTO_COUNT = 5
const DELAY = 1000;
const Layer = (props: LayerProps) => {
    const texture = props.origTexture.clone();
    const [currentAnimationPhase, setCurrentAnimationPhase] = useState<AnimationPhase>('running');
    const [targetAnimationPhase, setTargetAnimationPhase] = useState<AnimationPhase>('running');

    useEffect(() => {
        const currentAnimationTypeFromPhase = CorrecspondingAnimationType[currentAnimationPhase];
        const targetAnimationTypeFromPhase = CorrecspondingAnimationType[targetAnimationPhase];
        if (currentAnimationTypeFromPhase === props.animationType || currentAnimationPhase === 'upgrade_up') {
            if (currentAnimationPhase === targetAnimationPhase) {
                return;
            }
            if (currentAnimationTypeFromPhase === targetAnimationTypeFromPhase) {
                const timeout = setTimeout(() => {
                    setCurrentAnimationPhase(targetAnimationPhase);
                }, DELAY);
                return () => clearTimeout(timeout);
            }

            setTargetAnimationPhase(currentAnimationPhase);
        }

        if (currentAnimationPhase === 'stoped') {
            const timeoutToClear = setTimeout(() => {
                const animationPhase = props.animationType === 'running' ? 'running' : 'upgrade_up';
                setCurrentAnimationPhase(animationPhase);
                setTargetAnimationPhase(animationPhase);
            }, DELAY);
            return () => clearTimeout(timeoutToClear);
        }

        setTargetAnimationPhase('stoped');
    }, [props.animationType, currentAnimationPhase, targetAnimationPhase])

    texture.offset.set(0, props.layer / MOTO_COUNT)
    texture.repeat.set(1, 1 / MOTO_COUNT);

    const spriteRef = useRef<THREE.Sprite>(null);

    useFrame(({ clock }) => {
        const plain = document.getElementById(`${props.tokenId}_plain`) as any;
        if (!spriteRef.current || !plain) {
            return;
        }
        spriteRef.current.scale.set(plain.width, plain.height, 1);

        if (currentAnimationPhase === 'running' && props.runningAnimationSpeed) {
            const oldValue = spriteRef.current.position.y;
            const newValue = Math.sin(clock.elapsedTime * props.runningAnimationSpeed) * 2;

            let finalValue = newValue;
            if (targetAnimationPhase === 'stoped' && !isNewValueCloserToTarget(oldValue, newValue, 0)) {
                finalValue = 0;
                setCurrentAnimationPhase('stoped');
                clock.start();
            }

            spriteRef.current.position.y = finalValue;
        }


        const upgradeHighValue = (plain.height / 25) * (props.upgradeAnimationHight || 0);
        const step = upgradeHighValue / 1000;

        if (currentAnimationPhase === 'upgrade_up') {
            const oldValue = spriteRef.current.position.y;
            const newValue = oldValue + step * clock.elapsedTime;

            let finalValue = newValue;
            if (!isNewValueCloserToTarget(oldValue, newValue, upgradeHighValue)) {
                finalValue = upgradeHighValue;

                setTargetAnimationPhase('upgrade_down');
            }

            spriteRef.current.position.y = finalValue;
        }
        if (currentAnimationPhase === 'upgrade_down') {
            const oldValue = spriteRef.current.position.y;
            const newValue = oldValue - step * clock.elapsedTime;

            let finalValue = newValue;
            if (!isNewValueCloserToTarget(oldValue, newValue, 0)) {
                finalValue = 0;
                if (targetAnimationPhase === 'stoped') {
                    setCurrentAnimationPhase('stoped');
                } else {
                    setTargetAnimationPhase('upgrade_up');
                }
                clock.start();
            }

            spriteRef.current.position.y = finalValue;
        }
    });

    return (
        <>
            <sprite ref={spriteRef} scale={new THREE.Vector3(0, 0, 1)} position={[0, 0, props.layer]}>
                <spriteMaterial attach="material" map={texture} />
            </sprite>

        </>
    );

}

const isNewValueCloserToTarget = (oldValue: number, newValue: number, targetValue: number) => {
    const oldDiff = Math.abs(oldValue - targetValue);
    const newDiff = Math.abs(newValue - targetValue);
    return newDiff < oldDiff;
}
