
// Store that popup with ID was seen
const POPUP_SEEN = 'popupSeen';

export const setPopupSeen = (id: string) => {
    const popupSeen = getPopupSeen();
    popupSeen[id] = true;
    localStorage.setItem(POPUP_SEEN, JSON.stringify(popupSeen));
}

export const getPopupSeen = (id?: string) => {
    const popupSeen = localStorage.getItem(POPUP_SEEN);
    const popupObject = popupSeen ? JSON.parse(popupSeen) : {};

    if (id) {
        return popupObject[id] || false;
    }
    return popupObject;
}
