import { Link } from 'react-router-dom';
import PublicHeader from '../components/PublicHeader';
import { AuthPages, AUTH_PATH } from '../const/navigation';



export default function MintPage() {



    return (<>
        <PublicHeader />
        <div className="container">
            <div className='col-lg-8 text-center text-lg-start mb-5'>
                <img src={'/assets/mint.png'} alt="bike" className="half-hero-image" />
                <h1>Mint new motorbike</h1>
                <p className="lead">
                    You need a motorbike to start exploring the world of Motoverse.<br />
                    There are several classes of motorcycles and each of them has unique features.
                </p>
            </div>
            <Link to={`/${AUTH_PATH}/${AuthPages.garage}`}>
                <button type="button" className="btn btn-primary btn-lg px-5 mt-4">
                    Race On!
                </button>
            </Link>


        </div>
    </>
    )
}
