import { useEffect, useState } from 'react';
import { useMyBikes } from '../contexts/MyBikesContext';
import BikeCard from '../components/BikeCard';
import { Modal } from 'react-bootstrap';
import useQueryParam from '../hooks/useQueryParams';
import { useMExp } from '../contexts/MExpContext';
import { RarityUpgradeResult, useFunctions } from '../hooks/useFunctions';
import ExpoResultModal from '../components/activity/ExpoResultModal';
import { LoadingActivities } from '../components/Loading';
import { useFirebaseQuery } from '../hooks/firebaseHooks';
import { COLLECTIONS } from '../const/collections';
import { orderBy } from 'firebase/firestore';
import Activity from '../models/Activity';
import ActivityBlock from '../components/activity/ActivityBlock';
import { useUserContext } from '../contexts/UserContext';
import { isBikeAcceptableForActivity } from '../utils/bikes';
import { toast } from 'react-toastify';

export default function ActivitiesPage() {
    const { level } = useMExp();
    const { user, signupsPerBike } = useUserContext();
    const { bikes } = useMyBikes();
    const [selectedActivity, setSelectedActivity] = useState<Activity | null>(null);
    const [selectedBikeId, setSelectedBikeId] = useState('');
    const [procesing, setProcessing] = useState(false);
    const [justStartedBikeId, setJustStartedBikeId] = useState('');
    const { preselect } = useQueryParam()
    const [expoResult, setExpoResult] = useState<null | RarityUpgradeResult>(null);
    const [expoReviewsBikeId,] = useState('');
    const [activities] = useFirebaseQuery<Activity>(COLLECTIONS.activities, orderBy('order'));
    const { signUpForActivity } = useFunctions();

    useEffect(() => {
        if (preselect) {
            setSelectedBikeId(preselect);
        }
    }, [preselect])

    useEffect(() => {
        if (user.signups?.find(signup => signup.bikeId === selectedBikeId)) {
            setSelectedBikeId('');
        }
    }, [selectedBikeId, user.signups])

    useEffect(() => {
        if (!selectedBikeId || !selectedActivity) {
            return;
        }
        const selectedBike = (bikes || []).find(bike => bike.tokenId === selectedBikeId);
        const tier = Number(selectedBike?.attributeRecords?.tier || 0);
        const archetype = selectedBike?.attributeRecords?.archetype || '';

        if (selectedActivity.tierLimit && tier < selectedActivity.tierLimit) {
            setSelectedBikeId('');
            return;
        }
        if (selectedActivity.classLimit && !selectedActivity.classLimit.includes(archetype as any)) {
            setSelectedBikeId('');
            return;
        }
    }, [bikes, selectedActivity, selectedBikeId])

    const handleSignUp = async () => {
        if (!selectedBikeId || !selectedActivity) {
            return;
        }
        setProcessing(true);

        try {
            await signUpForActivity(selectedActivity.id, selectedBikeId);
            setSelectedBikeId('');
            setJustStartedBikeId(selectedBikeId)
        } catch (e) {
            toast.error((e as any).message);
        }

        setProcessing(false);
    }

    const racingBikeCount = user.signups?.length || 0;
    const reachedRacingLimit = racingBikeCount >= level;

    return <>
        <div className="container">
            <div className={`alert center ${reachedRacingLimit ? 'alert-success' : 'alert-info'}`}>
                <div>You currently have <span className='bold'>{racingBikeCount}</span> active {racingBikeCount === 1 ? 'bike' : 'bikes'} out of <span className='bold'>{level}</span> {level === 1 ? 'slot' : 'slots'}!</div>
            </div>

            <h2>1. Select Activity</h2>
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
                {activities.map(activity => <ActivityBlock
                    key={activity.id}
                    activity={activity}
                    selected={activity.id === selectedActivity?.id}
                    onClick={setSelectedActivity} />
                )}
            </div>


            <h2 className="mt-5">2. Select motorbike</h2>
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
                {bikes?.map(bike => {
                    const isAcceptable = selectedActivity ? isBikeAcceptableForActivity(bike, selectedActivity) : true;
                    const alreadyActive = !!signupsPerBike[bike.tokenId];
                    const disbaled = alreadyActive || !isAcceptable;
                    const classNames = `card bike-box h-100 ${selectedBikeId === bike.tokenId ? 'select' : ''} ${disbaled ? '' : 'pointer'}`

                    const handleBikeClick = () => {
                        if (!disbaled) {
                            setSelectedBikeId(bike.tokenId)
                        }
                    }

                    return <div className="col" onClick={handleBikeClick} key={bike.tokenId}>
                        <BikeCard bike={bike}
                            disabled={disbaled}
                            bikeBoxClass={classNames} />
                    </div>
                })}
            </div>
        </div>

        <div className="container mt-5">
            {reachedRacingLimit
                ? <div className={`alert center alert-info `}>
                    <div>All slots are full. Increase your level or end one of your ongoing activities in order to sign up for the next one</div>
                </div>
                : <div className="row">
                    <div className="d-grid gap-2 col-md-6 col-lg-3 mx-auto">
                        <button className="btn btn-primary btn-lg"
                            disabled={!selectedBikeId || !selectedActivity || procesing}
                            type="button" onClick={handleSignUp}>
                            Sign up!
                            {procesing && <span className="ms-2 spinner-border spinner-border-sm" />}
                        </button>


                    </div>

                </div>
            }

            <hr className="features-hr" />
        </div>

        <Modal show={!!justStartedBikeId} onHide={() => setJustStartedBikeId('')} className="modal fade" size='lg'>
            <div className="modal-content confetti-modal">
                <div className='activity-modal-header center'
                    style={{ backgroundImage: 'url(/assets/Activity_Background_01.png)', }}>
                    <LoadingActivities />
                </div>
                <div className='modal-body center flex-column'>
                    <h3 className="m-title">{procesing ? 'Get Ready' : 'Go Go Go!'}</h3>
                    <p className='m-text mb-5'>{procesing ? 'The activity is just about to begin' : 'The activity has started!'}</p>
                    <button type="button" className="btn btn-primary btn-lg" onClick={() => setJustStartedBikeId('')}>Continue</button>
                </div>
            </div>
        </Modal>
        <ExpoResultModal
            result={expoResult}
            bikeId={expoReviewsBikeId}
            onHide={() => setExpoResult(null)} />
    </>
}
