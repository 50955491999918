import React, { useMemo } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'react-bootstrap'
import { RarityUpgradeResult } from '../../hooks/useFunctions';
import TwitterShare from '../TwitterShare';

interface Props {
    bikeId: string;
    result: RarityUpgradeResult | null;
    onHide: () => void
}


const NoActivity = [
    "After carefully evaluating the bike, it appears that its rarity score should not be increased. Despite its unique design and features, the bike does not demonstrate active participation in enough activities to justify an upgrade.",
    "Upon close examination, it is evident that the bike does not engage in a sufficient number of activities to warrant an increase in rarity. Its limited involvement in various events hinders its eligibility for an upgrade.",
    "Regrettably, the bike's rarity score cannot be elevated due to its limited engagement in activities. To warrant an upgrade, it should demonstrate a greater presence and involvement in various events and gatherings.",
    "While the bike showcases notable qualities, its rarity score should not be heightened as it fails to partake in a sufficient number of activities. Expanding its participation in diverse events would be instrumental in justifying an increase in rarity.",
    "Considering the evaluation, it is evident that the bike's rarity score should remain unchanged. Its minimal involvement in activities does not align with the criteria necessary for an upgrade."
];

const NoActivityNorTier = [
    "After thorough evaluation, it is clear that the bike's rarity score should not be increased. Its limited participation in activities, combined with the subpar quality of its parts, does not justify an upgrade.",
    "Upon careful consideration, it is evident that the bike falls short in terms of both activity involvement and the quality of its parts. These factors contribute to the decision of not increasing its rarity score.",
    "Regrettably, the bike's rarity score cannot be elevated due to its insufficient engagement in activities and the inadequate quality of its parts. To warrant an upgrade, it should demonstrate increased activity and utilize better components.",
    "While the bike possesses certain appealing qualities, its rarity score should not be elevated due to its lack of activity participation and substandard parts. An upgrade would require improved engagement in events and the installation of higher-quality components.",
    "Considering the evaluation, it is clear that the bike's rarity score should remain unchanged. Its minimal involvement in activities and the inadequate quality of its parts do not meet the criteria necessary for an upgrade."
]

const NoTier = [
    "Upon careful evaluation, it is evident that the bike's rarity score should not be increased due to the inadequate quality of its parts. Without significant improvements in its components, an upgrade would not be justified.",
    "After thorough consideration, it is clear that the bike falls short in terms of the quality of its parts. The lack of superior components hinders its eligibility for a rarity score increase.",
    "Regrettably, the bike's rarity score cannot be elevated due to the substandard quality of its parts. To warrant an upgrade, it should address the issue of lacking components and incorporate better-quality parts.",
    "While the bike demonstrates satisfactory activity involvement, its rarity score should not be heightened due to the inadequacy of its parts. An upgrade would require substantial improvements in the quality of its components.",
    "Considering the evaluation, it is apparent that the bike's rarity score should remain unchanged due to the lack of quality in its parts. Without enhancements in its components, an increase in rarity is not warranted."
]

const NoMint = [
    "After careful evaluation, it is determined that the bike is ineligible for a rarity score increase. This decision is based on the fact that it was not included in the initial factory batches, which prevents any further upgrades.",
    "Upon thorough examination, it becomes apparent that the bike cannot undergo a rarity score upgrade. Its exclusion from the first batches produced by the factory renders it ineligible for any further enhancements.",
    "Regrettably, the bike cannot be upgraded in terms of rarity score due to its absence from the initial factory batches. This limitation prohibits any further modifications or improvements.",
    "Considering the evaluation, it is evident that the bike's rarity score cannot be increased as it was not part of the first factory batches. This exclusion restricts any further upgrades or enhancements.",
    "Based on the assessment, it is clear that the bike's rarity score should remain unchanged. Its lack of inclusion in the initial factory batches precludes any possibility of upgrades or advancements."
];



export default function ExpoResultModal(props: Props) {
    const { result, bikeId } = props;

    const denyReason = useMemo(() => {
        if (!result) {
            return '';
        }

        let deniedReasons = NoActivityNorTier;
        if (!result.conditions.mint) {
            deniedReasons = NoMint;
        } else if (result.conditions.claims) {
            deniedReasons = NoTier;
        } else if (result.conditions.tier) {
            deniedReasons = NoActivity;
        }

        return deniedReasons[Math.floor(Math.random() * deniedReasons.length)];
    }, [result])

    if (!result) {
        return null;
    }



    const renderBody = () => {
        if (result.upgraded) {
            return <div>
                <h3 className="text-center">Congratulations!</h3>
                <p className="text-center">Your bike has been upgraded to <strong className={`${result.newRarity}-color ms-1`}>{result.newRarity}</strong>!</p>
            </div>
        }

        return <div>
            <h3 className='center'>Bike rarity stays <strong className={`${result.newRarity}-color ms-1`}>{result.newRarity}</strong>!</h3>
            <p className='text-center'>{denyReason}</p>
        </div>

    }


    return (
        <Modal show={true} onHide={props.onHide} className="modal fade" size='lg'>
            <div className='faded-background' style={{ backgroundImage: 'url(/assets/Expo_01.png)' }}>

                <div className='position-relative' style={{ zIndex: 10 }}>

                    <ModalHeader closeButton={true}>Expo jury reviewed your bike!</ModalHeader>
                    <ModalBody className='px-5'>
                        {renderBody()}
                    </ModalBody>
                    <ModalFooter>
                        <TwitterShare tokenId={bikeId} text={`Excellent. Jury evaluated my bike as ${result.newRarity}!`} />
                        <button type="button" className="btn btn-primary btn-lg" onClick={props.onHide}>Continue</button>
                    </ModalFooter>
                </div>
            </div>
        </Modal >
    )
}
