import { useMemo } from "react";
import { MultiContentNode } from "../../models/MultiContentNode";
import { flatNode } from "../../utils/content";
import WhitepaperPageNavigationButton from "./WhitepaperPageNavigationButton";

interface WhitepaperPageNavigationProps {
    rootNode: MultiContentNode,
    currentId: number,
    onClick: (id: number) => void
}

const WhitepaperPageNavigation = (props: WhitepaperPageNavigationProps) => {
    const flatten = useMemo(() => flatNode(props.rootNode), [props.rootNode]);
    const index = flatten.findIndex(node => node.id === props.currentId);

    const cols: JSX.Element[] = [];

    if (index > 0) {
        const prevNode = flatten[index - 1];

        cols.push(<WhitepaperPageNavigationButton key={prevNode.id} node={prevNode} direction="left" onClick={props.onClick} />);
    }

    if (index < flatten.length - 1) {
        const nextNode = flatten[index + 1];
        cols.push(<WhitepaperPageNavigationButton key={nextNode.id} node={nextNode} direction="right" onClick={props.onClick} />);
    }

    return <div className='row mt-5 mb-2'>
        {cols}
    </div>
}

export default WhitepaperPageNavigation;

