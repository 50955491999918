import checkMark from '../assets/check_mark.svg';
import forSale from '../assets/for_sale_tag.png';
import { Link } from 'react-router-dom';
import { MOTO_UPGRADES } from '../const/tokens';
import BikeUpgradeCounter from './bike/BikeUpgradeCounter';
import MotoMetadata from '../models/MotoMetadata';



interface Props {
    bike: MotoMetadata,
    bikeBoxClass?: string,
    link?: string
    disabled?: boolean
    forSale?: boolean
}

export default function BikeCard(props: Props) {
    const { bike } = props;
    const bikeClass = bike?.attributeRecords?.archetype || '';

    const renderInnerCard = () => {
        return <>
            <div className="card-img">
                <img src={bike.image} alt={bike.tokenId} className="bike-img" />
                <img src={checkMark} alt='check' className="check_mark" />
            </div>

            {props.forSale && <img className="bike-forsale" src={forSale} alt='for sale' />}

            <div className={`bike-number ${bike?.attributeRecords?.rarity}`}>
                <div className="company">motoverse</div>
                <div className="number">{bike?.tokenId}</div>
            </div>

            <div className="tier">
                {bike?.attributeRecords?.tier}
            </div>

            <div className="bike-specs-card mb-2">
                <div className="row row-cols-auto justify-content-center">
                    {MOTO_UPGRADES.map(upgradeItem => {
                        const value = (bike?.attributeRecords?.[upgradeItem.name]) || 0;
                        return <div className="col center flex-column" key={upgradeItem.name}>
                            <img src={upgradeItem.image} alt="acceleration" className="icon" />
                            <BikeUpgradeCounter className="list" value={Number(value)} bikeClass={bikeClass as string} upgradeType={upgradeItem.name} />
                        </div>
                    })}
                </div>
            </div>
        </>
    }

    return (
        <div className={`col ${props.disabled && 'faded'}`}>
            <div className={props.bikeBoxClass || 'card bike-box h-100 center'}>
                {props.link ? <Link to={props.link} className='center flex-column'>{renderInnerCard()}</Link> : renderInnerCard()}
            </div>

        </div>
    )
}
