import './App.scss';
import 'react-toastify/dist/ReactToastify.css';

import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import HomePage from './pages/HomePage';
import Header from './components/Header';
import Footer from './components/Footer';
import { AuthPages, AUTH_PATH, Pages } from './const/navigation';
import RequireAuth from './components/RequireAuth';
import { ToastContainer } from 'react-toastify';
import GaragePage from './pages/GaragePage';
import BikePage from './pages/BikePage';
import GaleryPage from './pages/GaleryPage';
import SignPage from './pages/SignPage';
import DashboardPage from './pages/DashboardPage';
import MExpContextProvider from './contexts/MExpContext';
import MyBikesContextProvider from './contexts/MyBikesContext';
import ActivitiesPage from './pages/ActivitiesPage';
import FAQPage from './pages/FAQPage';
import ChallengesPage from "./pages/ChallengesPage";
import RankingsPage from "./pages/RankingsPage";
import Web3Provider from './contexts/Web3Context';
import PublicHeader from './components/PublicHeader';
import RequireNetwork from './components/RequireNetwork';
import MintPage from './pages/MintPage';
import ConfigProvider, { RequireConfig } from './contexts/ConfigContext';
import { AuthProvider, FirebaseAppProvider, FirestoreProvider, useFirebaseApp } from 'reactfire';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import firebaseConfig from './utils/firebaseConfig';
import WhitepaperPage from './pages/WhitepaperPage';
import UserContextProvider from './contexts/UserContext';
import EmailCollectingPopup from './components/public/EmailCollectingPopup';
import UnsubscribePage from './pages/UnsubscribePage';
import RiderPage from './pages/RiderPage';
import BikeHistoryPage from './pages/BikeHistoryPage';


export default function App() {

  return <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <FirebaseApp />
  </FirebaseAppProvider>
}

function FirebaseApp() {
  const useEmulators = process.env.REACT_APP_USE_EMULATORS || false;
  const firebaseApp = useFirebaseApp();
  const firestoreInstance = getFirestore(firebaseApp);
  if (useEmulators) {
    connectFirestoreEmulator(firestoreInstance, "localhost", 8080);
  }

  const authInstance = getAuth(firebaseApp);
  if (useEmulators) {
    connectAuthEmulator(authInstance, "http://localhost:9099");
  }

  return (
    <FirestoreProvider sdk={firestoreInstance}>
      <AuthProvider sdk={authInstance}>
        <ConfigProvider>
          <Web3Provider>
            <Router>
              <Routes>
                <Route path={`/${AUTH_PATH}/*`} element={<AuthApp />} />
                <Route path={`/${Pages.sign}`} element={<SignPage />} />
                <Route path={`/${Pages.faq}`} element={<FAQPage />} />
                <Route path={`/${Pages.whitepaper}`} element={<WhitepaperPage />} />
                <Route path={`/${Pages.mint}`} element={<MintPage />} />
                <Route path={`/${Pages.gallery}`} element={
                  <RequireConfig>
                    <RequireNetwork>
                      <PublicHeader />
                      <GaleryPage />
                    </RequireNetwork>
                  </RequireConfig>} />
                <Route path={`/${Pages.garage}/:bikeId`} element={
                  <RequireConfig>
                    <RequireNetwork>
                      <PublicHeader />
                      <BikePage />
                    </RequireNetwork>
                  </RequireConfig>} />
                <Route path={`/${Pages.history}/:bikeId`} element={
                  <RequireConfig>
                    <RequireNetwork>
                      <PublicHeader />
                      <BikeHistoryPage />
                    </RequireNetwork>
                  </RequireConfig>} />
                <Route path={`/${Pages.rider}/:address`} element={
                  <RequireConfig>
                    <RequireNetwork>
                      <PublicHeader />
                      <RiderPage />
                    </RequireNetwork>
                  </RequireConfig>} />
                <Route path="/unsubscribe" element={<UnsubscribePage />} />
                <Route path="/" element={<HomePage />} />
              </Routes>
              <Footer />
            </Router>
            <ToastContainer />
          </Web3Provider>
          <EmailCollectingPopup />
        </ConfigProvider>
      </AuthProvider>
    </FirestoreProvider>
  );
}

function AuthApp() {
  return (
    <RequireConfig>
      <RequireAuth>
        <UserContextProvider>
          <MyBikesContextProvider>
            <MExpContextProvider>

              <Header />
              <Routes>
                <Route path={`${AuthPages.gallery}`} element={<GaleryPage />} />
                <Route path={`${AuthPages.activities}`} element={<ActivitiesPage />} />
                <Route path={`${AuthPages.rider}/:address`} element={<RiderPage />} />
                <Route path={`${AuthPages.garage}/:bikeId`} element={<BikePage />} />
                <Route path={`${AuthPages.garage}`} element={<GaragePage />} />
                <Route path={`${AuthPages.challenges}`} element={<ChallengesPage />} />
                <Route path={`${AuthPages.rankings}`} element={<RankingsPage />} />
                <Route path="*" element={<DashboardPage />} />
              </Routes>
            </MExpContextProvider>
          </MyBikesContextProvider>
        </UserContextProvider>
      </RequireAuth>
    </RequireConfig>
  );
}
