import { useWeb3 } from '../contexts/Web3Context';
import SignPage from '../pages/SignPage';

type Props = {
    children: any
}

export default function RequireNetwork(props: Props) {
    const { isCorrectChain } = useWeb3();

    if (isCorrectChain) {
        return props.children;
    }

    return <div>
        <SignPage />
    </div>
}
