import React from 'react'

export default function Loading() {
    return (
        <div className="spinner-border m-auto" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    )
}

const imgSrcs = [
    '/assets/Book_01.png',
    '/assets/License_01.png',
    '/assets/Notepad_01.png',
    '/assets/Camp_01.png',
    '/assets/Parts_01.png',
    '/assets/Book_02.png',
    '/assets/License_02.png',
    '/assets/Notepad_02.png',
    '/assets/Parts_02.png',
    '/assets/Cone_01.png',
    '/assets/Prize_01.png',
]
export function LoadingActivities() {
    const [index, setIndex] = React.useState(0);

    React.useEffect(() => {
        const interval = setInterval(() => {
            setIndex((index) => {
                if (index === imgSrcs.length - 1) {
                    return 0;
                }
                return index + 1;
            });
        }, 1000);
        return () => clearInterval(interval);
    }, []);



    return <img src={imgSrcs[index]} alt="activities" />
}