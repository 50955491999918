import React from 'react'
import { Pages } from '../const/navigation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXTwitter } from '@fortawesome/free-brands-svg-icons'

interface Props {
    text?: string
    tokenId?: string
    riderId?: string
    className?: string
}

export default function TwitterShare(props: Props) {
    let text = 'Look at my bike; My bike is amazing; Give it a lick; Mmm, it tastes just like raisins.'
    if (props.text) {
        text = props.text;
    }
    const origin = window.location.origin.includes('staging') ? 'https://staging.motoverse.games' : 'https://motoverse.games';
    let url = origin;
    if (props.tokenId) {
        url = origin + '/' + Pages.garage + '/' + props.tokenId;
    } else if (props.riderId) {
        url = origin + '/' + Pages.rider + '/' + props.riderId;
    }
    const href = `https://twitter.com/intent/tweet?text=${text}&url=${url}&hashtags=motoverse`;

    return (
        <a href={href} target='_blank' rel='noreferrer' >
            <button className={`btn btn-twitter ${props.className}`}><FontAwesomeIcon size="lg" className="twitterLogo" icon={faXTwitter} /> Post </button>
        </a>
    )
}
