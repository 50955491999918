import MotoMetadata from "../models/MotoMetadata";
import InventoryItem from '../models/InventoryItem';
import { Firestore, doc, getDoc } from 'firebase/firestore';
import { COLLECTIONS } from '../const/collections';
import { fromFirebaseDoc } from './firebase';
import Activity from "../models/Activity";


export const loadBikesFromInventory = async (firestore: Firestore, inventory: InventoryItem[]): Promise<MotoMetadata[]> => {
    const bikeIds = inventory
        .filter(item => item.type === 'bike')
        .map(item => item.tokenId);

    const bikePromises = bikeIds.map(async tokenId => {
        const metadataSnap = await getDoc(doc(firestore, `${COLLECTIONS.MOTO_MINTED_METAS}/${tokenId}_latest`));
        return fromFirebaseDoc<MotoMetadata>(metadataSnap);
    });

    return await Promise.all(bikePromises);
}


export const isBikeAcceptableForActivity = (bike: MotoMetadata, activity: Activity): boolean => {
    const tier = Number(bike.attributeRecords?.tier || 0);
    const archetype = bike.attributeRecords?.archetype || '';

    if (activity.tierLimit && tier < activity.tierLimit) {
        return false;
    }
    if (activity.classLimit && !activity.classLimit.includes(archetype as any)) {
        return false;
    }
    return true;
}

const BASE_UPGRADE_PRICE = 120;
const EXPONENTIAL = 2;
const ABOVE_6_MULTIPLIER = 1.1;
export const calculateUpgradePrice = (currentLevel: number) => {
    if (currentLevel < 6) {
        return BASE_UPGRADE_PRICE * Math.pow(EXPONENTIAL, currentLevel);
    }
    return BASE_UPGRADE_PRICE * Math.pow(EXPONENTIAL, 5) * Math.pow(ABOVE_6_MULTIPLIER, currentLevel - 5);
}
