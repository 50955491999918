import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Web3 from 'web3';
import { AuthPages, AUTH_PATH, Pages, DISCORD_LINK } from '../const/navigation';
import hpOffroad from '../assets/hp_offroad.png'
import hpCruiser from '../assets/hp_cruiser.png'
import hpSport from '../assets/hp_sport.png'
import hpClassic from '../assets/hp_classic.png'
import hpClassicParam from '../assets/classic_class_params.png'
import hpOffroadParam from '../assets/offroad_class_params.png'
import hpCruiserParam from '../assets/cruiser_class_params.png'
import hpSportParam from '../assets/sport_class_params.png'
import roadmap from '../assets/Roadmap_graphic_01_cropped.png'
import tuning1Src from '../assets/TIER0.png'
import tuning2Src from '../assets/TIER6.png'
import tuning3Src from '../assets/tunning_banner_p3.png'
import PublicHeader from '../components/PublicHeader';
import { useWeb3 } from '../contexts/Web3Context';
import { swallowMetaMaskExceptionOrRethrow } from '../utils/errors'
import { recordMintEvent } from '../utils/analytics';

export default function HomePage() {
    const { isAuthenticated } = useWeb3();
    const { connectAccounts, isCorrectChain } = useWeb3();
    const hasMetamask = !!Web3.givenProvider;
    const navigate = useNavigate();

    const registerMintClick = () => recordMintEvent('mint_homepage')

    const handleLogin = async () => {
        registerMintClick();
        try {

            await connectAccounts();
            navigate(`/${AUTH_PATH}/${AuthPages.garage}`)
        } catch (e) {
            swallowMetaMaskExceptionOrRethrow(e);
        }
    }

    const callToActionLabel = 'Login to Dashboard'
    const renderCallToActionButton = () => {
        if (!hasMetamask || !isCorrectChain) {
            return <Link to={`/${Pages.sign}`}>
                <button type="button" className="btn btn-primary btn-lg px-5 mt-4" onClick={registerMintClick}>
                    {callToActionLabel}
                </button></Link>
        }

        if (isAuthenticated) {
            return <Link to={`/${AUTH_PATH}/${AuthPages.garage}`}>
                <button type="button" className="btn btn-primary btn-lg px-5 mt-4" onClick={registerMintClick}>
                    {callToActionLabel}
                </button>
            </Link>
        }

        return <button type="button" className="btn btn-primary btn-lg px-5 mt-4" onClick={handleLogin}>
            {callToActionLabel}
        </button>
    }

    return (
        <>
            <PublicHeader />
            <div className="hero_box" style={{ height: 800 }}>
                <div className="container position-relative">

                    <div className="row">
                        <div className="col-lg-6 herotext order-2 order-lg-1">
                            <h1 className="mt-5">Ride the future of crypto!</h1>
                            <p className="lead">
                                First motorbike game in the Avalanche ecosystem, <br />using NFTs as in-game assets and DeFi investment.<br />
                                {/*Play the game and get AVAX for it.<br />*/}
                                Get your motorbike. Go race. Upgrade. Profit.
                            </p>
                            <p className="lead">
                                Start exploring the world of Motoverse.
                            </p>
                            {renderCallToActionButton()}
                            <a href={DISCORD_LINK} target="_blank" rel="noreferrer" className="btn btn-outline-secondary btn-lg px-5 mt-4 ms-sm-3">Join Discord</a>
                        </div>
                        <div className="col-lg-6 d-flex align-items-center order-1 order-lg-2">
                            <div className="bike_animation">
                                <div className="bike_an"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="skidmark">
                <img src='/assets/skidmark_03.png' alt="skidmark" />
            </div>
            {/*<div className="hp_white py-5">*/}
            <div className="container howitworks py-5">
                <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 justify-content-center">
                    <div className="col">
                        <div className="card">
                            <img src='/assets/hp_race_icon.png' alt="bike" className="img-fluid p-5" />
                            <h4>Race</h4>
                            <p>Races are the main activity in Motoverse. Pick one of the various tracks, sign in suitable motorbike and race! Great rewards are awaiting.</p>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card">
                            <img src='/assets/hp_levelup_icon.png' alt="bike" className="img-fluid p-5" />
                            <h4>Level up</h4>
                            <p>By racing you earn MExp Points to Level Up. Higher Level allows you to buy more advanced Upgrades and more motorbikes to race simultaneously.</p>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card">
                            <img src='/assets/hp_earn_icon.png' alt="bike" className="img-fluid p-5" />
                            <h4>Earn MParts & AVAX</h4>
                            <p>Collect rewards in form of Motoverse currency MParts and AVAX. Purchase Upgrades and other amazing content. All powered by Avalanche blockchain.</p>
                        </div>
                    </div>
                </div>
            </div>
            {/*</div>*/}

            <div className="bg_dark">
                <div className="container">
                    <hr className="features-hr" />
                    <div className="row features">
                        <div className="col-md-7 mx-auto text-center">
                            <h2 className="text-white">Customize your motorbike</h2>
                            <p className="lead text-white">Each motorbike can be upgraded for MParts to improve NFT's performance, traits and value. In Motoverse, Upgrades are not just numbers like in other "P2E games", but they actually change the visuals of your motorbike!</p>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-5 order-md-1 order-3">
                            <img src={tuning1Src} alt="old bike" className="img-fluid" />
                        </div>
                        <div className="col-md-2 text-center order-2">
                            <img src={tuning3Src} alt="level up" className="img-fluid tuning3" />
                        </div>
                        <div className="col-md-5 order-md-3 order-1">
                            <img src={tuning2Src} alt="new bike" className="img-fluid" />
                        </div>
                    </div>
                    <hr className="features-hr" />
                </div>
            </div>

            <div className="container features">
                <hr className="features-hr" />
                {/*<div className="hero_divider"></div>*/}
                <h3 className="text-center h2">Four classes</h3>
                <p className="lead text-center">
                    Each bike fits on a different type of surface. <br />Choose carefully, otherwise you will end up on the ground.
                </p>
                <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4 g-4 mt-5">
                    <div className="col">
                        <div className="card h-100">
                            <img src={hpClassic} alt="bike" className="img-fluid" />
                            <div className="card-body">
                                <h4>Classic</h4>
                                <p>Great for everyday riding. <br />Your universal choice.</p>
                            </div>
                            <div className="card-footer">
                                <img src={hpClassicParam} alt="param" className="img-fluid paramImg" />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100">
                            <img src={hpSport} alt="bike" className="img-fluid" />
                            <div className="card-body">
                                <h4>Sport</h4>
                                <p>Fast but fragile. Be careful not to fall in a corner! For racing circuits as made.</p>
                            </div>
                            <div className="card-footer">
                                <img src={hpSportParam} alt="param" className="img-fluid paramImg" />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100">
                            <img src={hpCruiser} alt="bike" className="img-fluid" />
                            <div className="card-body">
                                <h4>Cruiser</h4>
                                <p>Do you like breathtaking torque? Cruiser is a clear choice. Just watch out for mud and gravel!</p>
                            </div>
                            <div className="card-footer">
                                <img src={hpCruiserParam} alt="param" className="img-fluid paramImg" />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100">
                            <img src={hpOffroad} alt="bike" className="img-fluid" />
                            <div className="card-body">
                                <h4>Off-road</h4>
                                <p>Durable and strong in the mud, but don't count on it on the racing circuit asphalt.</p>
                            </div>
                            <div className="card-footer">
                                <img src={hpOffroadParam} alt="param" className="img-fluid paramImg" />
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="features-hr" />
            </div>
            <div className="skidmark flip">
                <img src='/assets/skidmark_03.png' alt="skidmark" />
            </div>
            <div className="container features roadmapWrapper">
                <hr className="features-hr" />
                {/*<div className="hero_divider"></div>*/}
                <h3 className="text-center h2 mb-5">Roadmap</h3>
                <div className="row">
                    <div className="col">
                        <img src={roadmap} alt="roadmap" className="img-fluid " />
                    </div>
                </div>
                <div className="roadmapBox row row-cols-1 row-cols-md-2 row-cols-xl-4 g-4">
                    <div className="col">
                        <h4>Done</h4>
                        <ul>
                            <li>Website launch</li>
                            <li>Avalanche / Moralis Hackathon submission</li>
                            <li>Motorbike Generator</li>
                            <li>Smart contract launch and testing</li>
                            <li>Full Whitepaper</li>
                            <li>Public Mint on mainnet</li>
                        </ul>
                    </div>
                    <div className="col">
                        <h4>Q2 2023</h4>
                        <ul>
                            <li>Racer Dashboard</li>
                            <li>Passive Activity</li>
                            <li>Upgrades</li>
                            <li>Statistics and Leaderboards</li>
                        </ul>
                    </div>
                    <div className="col">
                        <h4>Q3 2023</h4>
                        <ul>
                            <li>Challenges and Badges</li>
                            <li>DEX listing</li>
                            <li>Arbitrage bot</li>
                            <li>Passive Races</li>
                        </ul>
                    </div>
                    <div className="col">
                        <h4>Future Highlights</h4>
                        <ul>
                            <li>Sandbox & Story </li>
                            <li>Customization</li>
                            <li>Motorcycle Clubs</li>
                        </ul>
                    </div>
                </div>
                <hr className="features-hr" />
            </div>
        </>
    )
}
