import React from 'react';
import PublicHeader from '../components/PublicHeader';
import { useContent } from '../hooks/useContent';

export default function FAQPage() {

    const content = useContent('faq');

    return <>
        <PublicHeader />
        <div className='container '>
            {content}
        </div>
    </>;
}
