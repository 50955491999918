import { NavLink, useNavigate } from 'react-router-dom';

import { AuthPages, AUTH_PATH } from '../const/navigation';

import { Container, Navbar } from "react-bootstrap";
import { useWeb3 } from '../contexts/Web3Context';
import { signOut } from 'firebase/auth';
import { useAuth } from 'reactfire';
import useQueryParam from '../hooks/useQueryParams';


export default function Header() {
    const { connectAccounts, isAuthenticated } = useWeb3();
    const navigate = useNavigate();
    const auth = useAuth();
    const { debug } = useQueryParam();

    const handleLogin = () => {
        connectAccounts()
    }
    const handleDisconnect = async () => {
        signOut(auth);
        navigate('/')
    }

    const showAllMenuItems = debug;
    return (
        <>
            <div className="headerBox py-3">
                <div className="container">
                    <Navbar bg="" expand="lg">
                        <Container>
                            <Navbar.Brand href="/">
                                <img src='/assets/logo_motoverse.svg' alt="Motoverse" className="d-inline-block align-text-top logo" />
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <ul className="navbar-nav mb-2 mb-lg-0 justify-content-end w-100">
                                    <li><NavLink to={`/${AUTH_PATH}`} end className="nav-link px-2">Dashboard</NavLink></li>

                                    <li><NavLink to={`/${AUTH_PATH}/${AuthPages.garage}`} className="nav-link px-2">Garage</NavLink></li>

                                    <li><NavLink to={`/${AUTH_PATH}/${AuthPages.activities}`} className="nav-link px-2">Activities</NavLink></li>
                                    <li><NavLink to={`/${AUTH_PATH}/${AuthPages.rankings}`} className="nav-link px-2">Rankings</NavLink></li>
                                    {showAllMenuItems &&
                                        <>
                                            <li><NavLink to={`/${AUTH_PATH}/${AuthPages.exchange}`} className="nav-link px-2">Exchange</NavLink></li>
                                        </>
                                    }

                                    <li><NavLink to={`/${AUTH_PATH}/${AuthPages.gallery}`} className="nav-link px-2">Gallery</NavLink></li>
                                    {isAuthenticated
                                        ? <li className="border-start"><div onClick={handleDisconnect} className="nav-link px-2 pointer">Disconnect</div></li>
                                        : <li className="border-start"><div onClick={handleLogin} className="nav-link px-2 pointer">Connect</div></li>
                                    }
                                </ul>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                </div>
            </div>
        </>
    )
}
