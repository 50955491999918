import { doc, onSnapshot } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react"
import { useFirestore } from "reactfire";
import Loading from "../components/Loading";
import Config, { ConfigFunctions } from "../models/Config";

const configContext = React.createContext<Config>({} as any);

const FUNCTION_ENDPOINTS: Record<string, ConfigFunctions> = {
    prod: {
        signUpForActivity: 'https://signupforactivity-7zar4lc4ka-ew.a.run.app',
        claimActivityRewards: 'https://claimactivityrewards-7zar4lc4ka-ew.a.run.app',
        getContent: 'https://getcontent-7zar4lc4ka-ew.a.run.app',
        upgradePart: 'https://upgradepart-7zar4lc4ka-ew.a.run.app',
    },
    staging: {
        signUpForActivity: 'https://signupforactivity-z3ec4cxzia-ew.a.run.app',
        claimActivityRewards: 'https://claimactivityrewards-z3ec4cxzia-ew.a.run.app',
        getContent: 'https://getcontent-z3ec4cxzia-ew.a.run.app',
        upgradePart: 'https://upgradepart-z3ec4cxzia-ew.a.run.app',
    },
    emulators: {
        signUpForActivity: 'http://127.0.0.1:5001/motoverse-ad355/europe-west1/signUpForActivity',
        claimActivityRewards: 'http://127.0.0.1:5001/motoverse-ad355/europe-west1/claimActivityRewards',
        getContent: 'http://127.0.0.1:5001/motoverse-ad355/europe-west1/getContent',
        upgradePart: 'http://127.0.0.1:5001/motoverse-ad355/europe-west1/upgradePart',
    },
}


const FUNCTIONS_URL = {
    staging: 'https://europe-west1-motoverse-ad355.cloudfunctions.net',
    prod: 'https://europe-west1-motoverse-prod.cloudfunctions.net'
}

const TMP_CONFIG: Config = {
    environment: 'prod',
    functionsUrl_DEPRECATEDD: '',
} as Config;

export function useConfig() {
    return useContext(configContext)
}

export function RequireConfig({ children }: any) {
    const config = useConfig();

    if (!config.functionsUrl_DEPRECATEDD) {
        return <Loading />
    }

    return children;
}

export default function ConfigProvider({ children }: any) {
    const [config, setConfig] = useState<Config | null>(null);
    const db = useFirestore();

    useEffect(() => {
        return onSnapshot(doc(db, "config", "public"), (doc) => {
            const configDoc = doc.data();
            let functionsUrl = configDoc?.environment === 'prod' ? FUNCTIONS_URL.prod : FUNCTIONS_URL.staging;
            let functionEndpoints = configDoc?.environment === 'prod' ? FUNCTION_ENDPOINTS.prod : FUNCTION_ENDPOINTS.staging;
            if (process.env.REACT_APP_USE_EMULATORS) {
                functionEndpoints = FUNCTION_ENDPOINTS.emulators;
                functionsUrl = 'http://localhost:5001/motoverse-ad355/europe-west1';
            }
            setConfig({
                ...configDoc,
                functionsUrl_DEPRECATEDD: functionsUrl,
                functionEndpoints,
            } as Config);
        });
    }, [db]);


    const finalConfig = config || TMP_CONFIG;

    return (
        <configContext.Provider value={finalConfig} >
            {children}
        </configContext.Provider>
    )
}