import { DISCORD_LINK, Pages, TWITTER_LINK, INSTAGRAM_LINK, FACEBOOK_LINK } from '../const/navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord } from '@fortawesome/free-brands-svg-icons'
import { faXTwitter } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { NavLink } from "react-router-dom";

export default function Footer() {

    return (
        <div className="footerBox">
            <div className="container">
                <div className="row">
                    <div className="col-md-2">
                        <img src='/assets/logo_motoverse.svg' alt="Motoverse" className="d-inline-block align-text-top logo" />

                    </div>
                    <div className="col-md-10 text-center text-md-end">
                        <ul className="list-inline">
                            <li className="list-inline-item"><NavLink to={`/${Pages.faq}`} className="">FAQ</NavLink></li>
                            <li className="list-inline-item"><NavLink to={`/${Pages.whitepaper}`} className="">Whitepaper</NavLink></li>
                            <li className="list-inline-item"><a href={DISCORD_LINK} target="_blank" rel="noreferrer" className="nav-link px-2">
                                <FontAwesomeIcon size="lg" className="discordLogo" icon={faDiscord} /></a>
                            </li>
                            <li className="list-inline-item"><a href={TWITTER_LINK} target="_blank" rel="noreferrer" className="nav-link px-2">
                                <FontAwesomeIcon size="lg" className="twitterLogo" icon={faXTwitter} /></a>
                            </li>
                            <li className="list-inline-item"><a href={INSTAGRAM_LINK} target="_blank" rel="noreferrer" className="nav-link px-2">
                                <FontAwesomeIcon size="lg" className="instagramLogo" icon={faInstagram} /></a>
                            </li>
                            <li className="list-inline-item"><a href={FACEBOOK_LINK} target="_blank" rel="noreferrer" className="nav-link px-2">
                                <FontAwesomeIcon size="lg" className="facebookLogo" icon={faFacebook} /></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
