import React, { useContext, useEffect, useState } from "react";
import { loadBikesFromInventory } from "../utils/bikes";
import { useWeb3 } from "./Web3Context";
import { useUserContext } from "./UserContext";
import { collection, getDocs, query, where } from "firebase/firestore";
import { COLLECTIONS } from "../const/collections";
import { useFirestore } from "reactfire";
import { fromFirebaseDocs } from "../utils/firebase";
import InventoryItem from "../models/InventoryItem";
import MotoMetadata from "../models/MotoMetadata";

interface MyBikesContext {
    bikes: MotoMetadata[] | null,
    refetch: () => Promise<void>
}
const DEFAULT_VALUE: MyBikesContext = {
    bikes: null,
    refetch: async () => { },
}

const myBikesContext = React.createContext<MyBikesContext>(DEFAULT_VALUE);

interface UserBikes {
    bikes: MotoMetadata[],
    refetchBikes: () => void
}
export function useUserBikes(uid: string): UserBikes {
    const [bikes, setBikes] = useState<MotoMetadata[]>([]);
    const [refetchCounter, setRefetchCounter] = useState(0);
    const firestore = useFirestore();

    const refetch = () => {
        setRefetchCounter(prev => prev + 1);
    }

    useEffect(() => {
        if (!uid) {
            return;
        }
        const effectMain = async () => {
            const q = query(collection(firestore, COLLECTIONS.inventory),
                where('ownerId', '==', uid),
                where('type', '==', 'bike'));
            const inventorySnap = await getDocs(q);
            const inventory = fromFirebaseDocs<InventoryItem>(inventorySnap.docs);
            const bikes = await loadBikesFromInventory(firestore, inventory);
            setBikes(bikes);
        };

        effectMain();
    }, [uid, firestore, refetchCounter]);
    return {
        bikes,
        refetchBikes: refetch
    };
}

export function useMyBikes() {
    return useContext(myBikesContext)
}

export default function MyBikesContextProvider({ children }: any) {
    const { account } = useWeb3();
    const { inventory } = useUserContext();
    const [bikes, setBikes] = useState<MotoMetadata[]>([]);
    const [refetchCounter, setRefetchCounter] = useState(0);
    const firestore = useFirestore();

    useEffect(() => {
        loadBikesFromInventory(firestore, inventory).then(newBikes => {
            setBikes(newBikes);
        });
    }, [refetchCounter, inventory, firestore])

    const refetch = async () => {
        if (account) {
            setRefetchCounter(prev => prev + 1);
        }
    }

    return <myBikesContext.Provider value={{ refetch, bikes }} >
        {children}
    </myBikesContext.Provider>
}