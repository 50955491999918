import { useState } from 'react'
import { AuthPages, AUTH_PATH } from '../const/navigation';
import newBike from '../assets/new_bike.png'
import BikeCard from '../components/BikeCard';
import { useMyBikes } from '../contexts/MyBikesContext';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function GaragePage() {
    const [showModal, setShowModal] = useState(false);
    const { bikes } = useMyBikes();

    let highestId = Math.max(...(bikes || []).map(bike => Number(bike.tokenId)));
    return (
        <div className="container">
            <div className="row">
                <div className='col-lg-8 text-center text-lg-start mb-5'>
                    <img src={'/assets/mint.png'} alt="bike" className="half-hero-image" />
                    <h1>Motorbikes</h1>
                    <p className="lead">
                        You need a motorbike to start exploring the world of Motoverse.<br />
                        There are several classes of motorcycles and each of them has unique features.
                    </p>
                </div>
            </div>
            {!!bikes?.length && <h2>My collection</h2>}
            <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-4">
                {(bikes || []).map(bike => <BikeCard bike={bike}
                    key={bike.tokenId}
                    link={`/${AUTH_PATH}/${AuthPages.garage}/${bike.tokenId}`} />

                )}

            </div>



            <Modal show={showModal} onHide={() => setShowModal(false)} className="modal fade" size='lg'>
                <div className="modal-content">
                    <img src={newBike} alt="new bike" />

                    <div className="center flex-column">
                        <h3 className="m-title">Woo hoo!</h3>
                        <p className="m-text mb-5">Your brand new motorbike just arrived!</p>
                        <div className='mb-5'>
                            <button type="button" className="btn btn-secondary btn-lg me-3" onClick={() => setShowModal(false)}>Continue</button>
                            <Link to={`/${AUTH_PATH}/${AuthPages.garage}/${highestId}`}><button type="button" className="btn btn-primary btn-lg ms-3">Show me!</button></Link>
                        </div>
                    </div>

                </div>
            </Modal>

            <hr className="features-hr" />
        </div>
    )
}
